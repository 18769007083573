import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {submitProcessingTaskRequest} from "../../../redux/reducers/readyTasksReducer/actions";
import ServiceButton from "../../ServiceButton/ServiceButton";
import {IDLE} from "../../StatusesComponets/statusConstants";

function TaskButtons({isMobile}) {
    const dispatch = useDispatch()
    const {selectedTaskName, submittingProcessingTask} = useSelector((state) => state.readyTasksReducer)
    const {taskStatus} = useSelector(state => state.statusReducer)
    return (
        <>
            {taskStatus === IDLE && selectedTaskName ? <>
                <ServiceButton
                    variant={'contained'}
                    style={isMobile ? {position: 'absolute', marginRight: '10px',
                        right: 0, bottom: '40%', padding: '3px 10px'} : {marginLeft: '20px'}}
                    color={'secondary'}
                    buttonName={'SubmitTask'}
                    width={isMobile ? 'initial' : 170}
                    height={isMobile ? 'initial' : 40}
                    loadingCondition={submittingProcessingTask}
                    onClick={() => {
                        dispatch(submitProcessingTaskRequest())
                    }}
                />
                {/*<ServiceButton variant={'outlined'}*/}
                {/*               width={180}*/}
                {/*               style={{marginLeft: '20px'}}*/}
                {/*               buttonName={'DeleteButtonTask'}*/}
                {/*               onClick={() => dispatch(taskDeleteRequest(selectedTaskName))}*/}
                {/*               color={'error'}/>  */}
            </> : null}
        </>
    );
}

export default TaskButtons;
