import {
    GET_PLANNED_TASKS_FAIL,
    GET_PLANNED_TASKS_REQUEST, GET_PLANNED_TASKS_SUCCESS,
    GET_READY_TASKS_FAIL,
    GET_READY_TASKS_REQUEST,
    GET_READY_TASKS_SUCCESS,
    GET_SINGLE_READY_TASK_FAIL,
    GET_SINGLE_READY_TASK_REQUEST,
    GET_SINGLE_READY_TASK_SUCCESS,
    SET_LIST_OF_MISSED_PARTS, SUBMIT_TASK_PROCESSING_FAIL,
    SUBMIT_TASK_PROCESSING_REQUEST,
    SUBMIT_TASK_PROCESSING_SUCCESS,
    TASK_DELETE_MODEL_FAIL,
    TASK_DELETE_MODEL_REQUEST,
    TASK_DELETE_MODEL_SUCCESS
} from "./types";

export const getPlannedTasksListRequest = () => ({
    type: GET_PLANNED_TASKS_REQUEST
})

export const getPlannedTasksListSuccess = (data) => ({
    type: GET_PLANNED_TASKS_SUCCESS, payload: data
})

export const getPlannedTasksListFail = () => ({
    type: GET_PLANNED_TASKS_FAIL
})

export const getReadyTasksListRequest = (filter) => ({
    type: GET_READY_TASKS_REQUEST, filter
})

export const getReadyTasksListSuccess = (data) => ({
    type: GET_READY_TASKS_SUCCESS, payload: data
})

export const getReadyTasksListFail = () => ({
    type: GET_READY_TASKS_FAIL
})


export const getSingleReadyTaskRequest = (taskName, count) => ({
    type: GET_SINGLE_READY_TASK_REQUEST, payload: taskName, count
})

export const getSingleReadyTaskSuccess = (data) => ({
    type: GET_SINGLE_READY_TASK_SUCCESS, payload: data
})

export const getSingleReadyTaskFail = () => ({
    type: GET_SINGLE_READY_TASK_FAIL
})

export const setListOfMissedParts = (list) => ({
    type: SET_LIST_OF_MISSED_PARTS, payload: list
})




export const taskDeleteRequest = (taskName) => ({
    type: TASK_DELETE_MODEL_REQUEST, payload: taskName
})
export const taskDeleteSuccess = (taskName) => ({
    type: TASK_DELETE_MODEL_SUCCESS, payload: taskName
})
export const taskDeleteFail = () => ({
    type: TASK_DELETE_MODEL_FAIL
})



export const submitProcessingTaskRequest = () => ({
    type: SUBMIT_TASK_PROCESSING_REQUEST
})
export const submitProcessingTaskSuccess = (newTask) => ({
    type: SUBMIT_TASK_PROCESSING_SUCCESS, payload: newTask
})
export const submitProcessingTaskFail = () => ({
    type: SUBMIT_TASK_PROCESSING_FAIL
})
