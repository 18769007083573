import axios from "axios";
import {store} from "../redux/store";
import {userLoginFail} from "../redux/reducers/userReducer/actions";
import {sendGeneralMessage} from "../redux/reducers/layoutReducer/actions";


const baseURL = process.env.REACT_APP_BACKEND_URL

export const anonymousUser = axios.create({
    baseURL,
    timeout: 20000,
});


export const streamAxios = axios.create({
    baseURL: process.env.REACT_APP_STREAM_URL,
    timeout: 20000,
});


export const authAxios =
    axios.create({
        baseURL: `${baseURL}api/`,
        timeout: 20000,
    });


authAxios.interceptors.response.use(response => {
    return response;
}, error => {
    if ((!error.response && error.message === "Network Error") || error.response?.status === 401) {
        store.dispatch(userLoginFail())
        const message = error.response?.status === 401 ? "Токен недействителен" : 'Проблемы с сетью'
        store.dispatch(sendGeneralMessage(message, 'error'))
    }
    return error;
});
