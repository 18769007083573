import React, {useEffect} from 'react';
import StatusesComponents from "../../components/StatusesComponets/StatusesComponents";
import StatusSocket from "../../components/StatusesComponets/StatusSocket/StatusSocket";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import Box from "@mui/material/Box";
import {Grid, Tab, Tabs} from "@mui/material";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import {useDispatch, useSelector} from "react-redux";
import PdfScheme from "./PdfScheme";
import Rack from "../../components/Rack/Rack";
import {turnOnViewerLoading} from "../../redux/reducers/modelsReducer/actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function StatusMainPage({isMobile}) {
  const {t} = useTranslation()
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch()
  const {selectedModel} = useSelector((state) => state.statusReducer)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    dispatch(turnOnViewerLoading())
  })

  return (
    <ErrorBoundary message={'Что-то пошло нет'}>
      <StatusSocket/>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'white' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:"#141414"}}>
            <Tab label={t('status')} {...a11yProps(0)} style={value === 0 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
            <Tab label={t('general_view')} {...a11yProps(1)} style={value === 1 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
            <Tab label={t('virtual_view')} {...a11yProps(2)} style={value === 2 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
            <Tab label={t('scheme')} {...a11yProps(3)} style={value === 3 ? {backgroundColor: '#303030', color:"white"} : {color:"white"}}/>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <StatusesComponents isMobile={isMobile}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ErrorBoundary message={'Что-то пошло нет'}>
            <Rack/>
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container style={isMobile ? {width: '100%', height: '100%', display: 'block'}
            : {width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} sm={12} style={isMobile ? {height: '54%', marginTop: '5px'} : {}}>
              <ErrorBoundary message={'Что-то пошло нет'}>
                <Viewer3DContainer selectedModel={selectedModel} isMobile={isMobile} page={'v_rabote'}/>
              </ErrorBoundary>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PdfScheme selectedModel={selectedModel}/>
        </TabPanel>
      </Box>
    </ErrorBoundary>
  );
}

export default StatusMainPage;
