import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import {
    SCANNING_BEAM_ERROR,
    SCANNING_BEAM_IS_RUNNING,
    SCANNING_BEAM_ROBOT_ERROR,
    SCANNING_BEAM_SUCCESS
} from "../statusConstants";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import StatusInfo from "../GeneralComponents/StatusInfro/StatusInfo";
import {SocketContext} from "../../../redux/customReducer/SocketContext";


const ScanningBeamComponent = ({screenVersion =false, isMobile}) => {
    const {scanningBeamStatus} = useSelector(state => state.statusReducer)
    const {state: {ws}} = useContext(SocketContext);

    const statusRender = (scanningBeamStatus) => {
        switch (scanningBeamStatus) {
            case SCANNING_BEAM_SUCCESS:
                ws.emit('start_after_scan_beam')
                return <StatusInfo status={scanningBeamStatus} isMobile={isMobile}/>
            case SCANNING_BEAM_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={scanningBeamStatus}/> : <ProcessingActionScreen status={SCANNING_BEAM_IS_RUNNING}/>
            case SCANNING_BEAM_ERROR:
            case SCANNING_BEAM_ROBOT_ERROR:
                return <StatusScanningError status={scanningBeamStatus} screenVersion={screenVersion} isMobile={isMobile}/>
            default:
                return <UnknownStatus isMobile={isMobile}/>
        }
    }

    return statusRender(scanningBeamStatus)
};

export default ScanningBeamComponent;
