import React from 'react';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import ErrorBoundary from "../../../LogicalComponents/ErrorBoundary/ErrorBoundary";
import ModalViewer from "../../../ThreeJsComponents/ModalViewer/ModalViewer";
import MobileStatusStart from "../../StatusStartSettings/MobileStatusStart";


function MobileMountingComponent() {
  const {t} = useTranslation()
  const mobileStyle = {
    height: '100%',
    background: 'red',
    backgroundColor: 'white',
  }

  return <div style={{ height: '100%' }}
  >
    <div style={{height: '60%'}}>
      <ErrorBoundary message={'Что-то пошло нет'}>
        <Box sx={mobileStyle}>
          <ModalViewer/>
        </Box>
      </ErrorBoundary>
    </div>
    <Box style={{marginTop: '26px'}}>
      <MobileStatusStart/>
    </Box>
  </div>
}

export default MobileMountingComponent;
