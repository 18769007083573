import {LOGIN_FAIL, LOGIN_SUCCESS} from "../userReducer/types";
import {SET_CHOSEN_MENU_TAB} from "../layoutReducer/types";


import initialViewerSettings from "../../../utils/initialViewerSettings";
import {SET_INITIAL_VIEWER_SETTINGS, SET_PARTS_TYPE, SET_VIEWER_CONSTANTS} from "./types";


const initialState = {
    accessToken: null,
    isAuthenticated: false,
    chosenSection: 'Models',
    viewerSettings: initialViewerSettings,
    partsType: 'parts'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {...state, accessToken: action.payload, isAuthenticated: true}
        case LOGIN_FAIL:
            return {...state, accessToken: null, isAuthenticated: false}
        case SET_CHOSEN_MENU_TAB:
            return {...state, chosenSection: action.payload}
        case SET_INITIAL_VIEWER_SETTINGS:
            return {...state, viewerSettings: initialViewerSettings}
        case SET_VIEWER_CONSTANTS:

            return {
                ...state,
                viewerSettings: action.payload
            }
        case SET_PARTS_TYPE:
            return {...state,partsType: action.payload}
        default:
            return state;
    }
};

export default reducer;