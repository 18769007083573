import React from 'react';
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';


function Columns(data) {
  const {t} = useTranslation();

  return {
    rows: data.map((item) => {
      return {
        progress: item.statuses.task_status === 'SUCCESSFULLY_ENDED' ?
          <CircleIcon style={{fontSize:'25px',color:'green'}}/>
          :
          <CircleIcon style={{fontSize:'25px',color:'red'}}/>,
        name: item.assembly_name,
        rotations: item.total_number_turns ? item.total_number_turns - 1 : 0,
        assemble_start: item.start_time,
        efficient_time: item.efficient_time,
        assemble_time: item.total_time
        }
    })
  }
}

export default Columns;
