import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GeneralTable from "../GeneralTable/GeneralTable";
import {getSingleReadyTaskRequest} from "../../../redux/reducers/readyTasksReducer/actions";
import useCreateTableData from "../../LogicalComponents/Hooks/useCreateTableData";

function ReadyTasksTable({isMobile, changeFilter, filter}) {

    const headers = isMobile ? ['CadModelHeader', 'WillBeAssembledHeader','RotationHeader']
      : ['','CadModelHeader','DownloadTimeHeader', 'WillBeAssembledHeader','RotationHeader']
    const {selectedTaskName, readyTasksList,readyTasksAreLoading} = useSelector((state => state.readyTasksReducer))
    const {rows}  = useCreateTableData(readyTasksList, isMobile)
    const dispatch = useDispatch()
    const onRowClick = (id, count) => {
        dispatch(getSingleReadyTaskRequest(id, count))
    }
    return <GeneralTable headers={headers} rows={rows} onRowClick={onRowClick} isMobile={isMobile}
                         activeItem={selectedTaskName} tableIsLoading={readyTasksAreLoading}
                         changeFilter={changeFilter} filter={filter}/>
}

export default ReadyTasksTable;
