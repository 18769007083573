import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {setTableLength} from "../../../redux/reducers/statusReducer/actions";
import {useTranslation} from "react-i18next";

const TableInput = ({setTableIsOk}) => {

    const [tableNumber, setTableNumber] = useState(0)
    const dispatch = useDispatch()
    const createInput = () => {
        return [1, 2, 3, 4, 5, 6].reduce((acc, item) => {
            acc[item] = false
            return acc
        }, {})
    }

    const [activeTables, setActiveTables] = useState(createInput())

    const setTable = (number) => {
        const currentState = activeTables[number]
        setActiveTables({...activeTables, [number]: !currentState})

    }

    const calculateNumberOfTables = (activeTables) => {
        let counter = 0;
        Object.values(activeTables).forEach((item) => {
            if (item) counter++;
        })
        return counter
    }

    const checkPattern = (activeTables, tableNumber) => {
        let binaryRepresentation = ''
        Object.values(activeTables).forEach((item) => {
            if (item) {
                binaryRepresentation += '1'
            } else {
                binaryRepresentation += '0'
            }
        })
        return binaryRepresentation.search('1'.repeat(tableNumber)) !== -1
    }


    useEffect(() => {
        const tableNumber = calculateNumberOfTables(activeTables)
        setTableNumber(tableNumber)
        setTableIsOk(checkPattern(activeTables, tableNumber))
        dispatch(setTableLength(tableNumber * 2000))

    }, [activeTables])

    return (
        <div>
            <div style={{display: 'flex'}}>
                {[1, 2, 3, 4, 5, 6].map((item) => {
                    return <div
                        onClick={() => {
                            setTable(item)
                        }}
                        key={item}
                        style={{
                            backgroundColor: !activeTables[item] ? '#212121' : '#54fcef',
                            flexBasis: '12%', height: '3.5vh',
                            border: '0.1px solid #141414',
                            cursor: 'pointer'
                        }}/>
                })}

            </div>

            <Typography color={'white'}
                        style={{fontSize: '1.5vh', marginTop: '10px'}}>Total {tableNumber} of
                6 tables</Typography>


        </div>
    );
};

export default TableInput;