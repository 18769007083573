import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setRedirectPath} from "../../../redux/reducers/layoutReducer/actions";

const RedirectPath = () => {
    const {redirectPath} =  useSelector((state) => state.layoutReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(()=>{
        if(redirectPath){
            navigate(redirectPath);
            dispatch(setRedirectPath(null))
        }
    },[redirectPath])
    return null
};

export default RedirectPath;