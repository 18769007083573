import React from 'react';
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Idle from "./Idle/Idle";
import {
    EXECUTOR_AND_PLANNING_ACTION,
    EXECUTOR_ERROR,
    EXECUTOR_SUCCESS, GETTING_INITIAL_INFORMATION,
    IDLE,
    LABELING_AND_PLANNING_ACTION,
    LABELING_ERROR,
    MOUNTING,
    PLANNER_ERROR,
    PLANNER_SUCCESS,
    SCANNING_BEAM_ACTION,
    SCANNING_BEAM_ERROR,
    SCANNING_LESS_PARTS_ERROR,
    SCANNING_PARTS_ACTION,
    SCANNING_PARTS_ERROR,
    SCANNING_REVERSE_PARTS_ERROR,
    SCANNING_UNMATCHED_PARTS_ERROR, TURN_BEAM
} from "../StatusesComponets/statusConstants";
import Screen1Mounting from "./Mounting/Screen1Mounting";
import Screen2Mounting from "./Mounting/Screen2Mounting";
import ExecutorPlannerLabeling from "../StatusesComponets/ExecutorPlannerLabeling/ExecutorPlannerLabeling";

import {Button} from "@mui/material";
import UnknownStatus from "../StatusesComponets/GeneralComponents/UnknownStatus/UnknownStatus";
import ScanningBeamComponent from "../StatusesComponets/ScanningBeamComponent/ScanningBeamComponent";
import ScanningPartsComponent from "../StatusesComponets/ScanningPartsComponent/ScanningPartsComponent";
import StatusInfo from "../StatusesComponets/GeneralComponents/StatusInfro/StatusInfo";
import RotateBeam from "../StatusesComponets/GeneralComponents/RotateBeam/RotateBeam";


const ScreensComponent = ({screen1 = true}) => {
    const {
        taskStatus,
        scanningBeamStatus,
        scanningPartsStatus,
        labelingStatus,
        executorStatus,
        plannerStatus,

    } = useSelector(state => state.statusReducer)

    const handle = useFullScreenHandle();

    const handleFullscreen = () => {
        handle.active ? handle.exit() : handle.enter()
    }


    const getStatusColor = (status) => {
        switch (status) {
            case SCANNING_BEAM_ERROR:
            case SCANNING_PARTS_ERROR:
            case SCANNING_REVERSE_PARTS_ERROR:
            case SCANNING_LESS_PARTS_ERROR:
            case SCANNING_UNMATCHED_PARTS_ERROR:
            case LABELING_ERROR:
            case EXECUTOR_ERROR:
            case PLANNER_ERROR:
                return '#ff0000'
            case EXECUTOR_SUCCESS:
            case PLANNER_SUCCESS:
                return '#00ff55'
            default:
                return 'rgb(11 250 240)'
        }
    }

    const getStatusStyle = (status) => {
        switch (status) {
            case SCANNING_BEAM_ACTION:
                return getStatusColor(scanningBeamStatus)
            case SCANNING_PARTS_ACTION:
                return getStatusColor(scanningPartsStatus)
            case LABELING_AND_PLANNING_ACTION:
                return ` linear-gradient(90deg, ${getStatusColor(plannerStatus)} 50%, ${getStatusColor(labelingStatus)} 50%)`
            case EXECUTOR_AND_PLANNING_ACTION:
                return ` linear-gradient(90deg, ${getStatusColor(plannerStatus)} 50%, ${getStatusColor(executorStatus)} 50%)`
            case TURN_BEAM:
                return 'none'
            default:
                return getStatusColor(taskStatus)
        }
    }


    const getStatusComponent = (status) => {
        switch (status) {
            case IDLE:
                return <Idle/>
            case MOUNTING:
                return screen1 ? <Screen1Mounting/> : <Screen2Mounting/>
            case SCANNING_BEAM_ACTION:
                return <ScanningBeamComponent screenVersion/>
            case SCANNING_PARTS_ACTION:
                return <ScanningPartsComponent screenVersion/>
            case LABELING_AND_PLANNING_ACTION:
            case EXECUTOR_AND_PLANNING_ACTION:
                return <ExecutorPlannerLabeling status={status} screenVersion/>
            case GETTING_INITIAL_INFORMATION:
                return <StatusInfo status={GETTING_INITIAL_INFORMATION}/>
            case TURN_BEAM:
                return  <RotateBeam/>

            default:
                return <UnknownStatus/>
        }
    }
    return (<React.Fragment>
            <FullScreen handle={handle}>
                <Box style={{
                    background: getStatusStyle(taskStatus),
                    width: '100%',
                    height: '100vh',
                    position: 'relative'
                }}>

                    {getStatusComponent(taskStatus)}
                    <Button style={{position: 'absolute', right: '20px', bottom: '20px'}}
                            onClick={handleFullscreen}

                    >
                        {!handle.active ? <FullscreenIcon style={{fontSize: '60px', color: 'white'}}/> :
                            <FullscreenExitIcon style={{fontSize: '60px', color: 'white'}}/>}
                    </Button>

                </Box>
            </FullScreen>

        </React.Fragment>

    );
};

export default ScreensComponent;


