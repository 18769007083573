import {call, put, takeLatest} from 'redux-saga/effects'
import {LOGIN_REQUEST} from "./types";
import {loginUserRequest} from "./requests";
import {userLoginFail, userLoginSuccess} from "./actions";
import {sendGeneralMessage} from "../layoutReducer/actions";


function* userLoginSaga(action) {
    try {
        const response = yield call(loginUserRequest, action.payload)
        const {access_token} = response.data
        yield put(userLoginSuccess(access_token))

    } catch (err) {
        const {response} = err
        let message = response && response.status === 401 ? response.data.detail : 'Ошибка с сетью'
        yield put(userLoginFail())
        yield put(sendGeneralMessage(message, 'error'))
    }
}

export default function* userSaga() {
    yield takeLatest(LOGIN_REQUEST, userLoginSaga)
}