import {
    GET_ARCHIVE_TASKS_REQUEST,
    GET_ARCHIVE_TASKS_SUCCESS,
    GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_EXCEL, GET_GRAPH_EXCEL_SUCCESS,
    SELECT_ARCHIVED_TASK

} from "./types";


const initialState = {
    archiveTasksList: [],
    archiveTasksAreLoading: false,
    selectedTask: null,
    graphDataLoading: false,
    graphData: [],
    maxValue: 100,
    first_load: true,
    filter_start: '',
    filter_end: '',
    weights: [],
    total_weight: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARCHIVE_TASKS_REQUEST:
            return {...state, archiveTasksAreLoading: true}
        case GET_GRAPH_DATA:
            return {...state, graphDataLoading: true}
        case GET_GRAPH_DATA_SUCCESS:
            return {...state, graphData: action.payload, maxValue: action.maxValue, graphDataLoading: false,
                first_load: false, filter_start: action.dates.start, filter_end: action.dates.end,
                weights: action.weights, total_weight: action.total_weight}
        case GET_GRAPH_EXCEL:
            return {...state, graphExcelLoading: true}
        case GET_GRAPH_EXCEL_SUCCESS:
            return {...state,  graphExcelLoading: false}
        case GET_ARCHIVE_TASKS_SUCCESS:
            return {...state, archiveTasksList: action.payload, archiveTasksAreLoading: false}
        case SELECT_ARCHIVED_TASK:
            let task = state.archiveTasksList[action.payload];
            return {...state, selectedTask: task}
        default:
            return state;
    }
};

export default reducer;
