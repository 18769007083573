import {call, put, select, takeLatest} from "redux-saga/effects";
import {
    getPlannedTasksListFail,
    getPlannedTasksListSuccess,
    getReadyTasksListFail,
    getReadyTasksListSuccess,
    getSingleReadyTaskFail,
    getSingleReadyTaskSuccess,
    setListOfMissedParts,
    submitProcessingTaskFail,
    submitProcessingTaskSuccess,
    taskDeleteFail,
    taskDeleteSuccess
} from "./actions";
import {
    GET_PLANNED_TASKS_REQUEST,
    GET_READY_TASKS_REQUEST,
    GET_SINGLE_READY_TASK_REQUEST, SET_PLANNED_TASK_REQUEST,
    SUBMIT_TASK_PROCESSING_REQUEST,
    TASK_DELETE_MODEL_REQUEST
} from "./types";
import {
    createPendingTask,
    getPlannedTasksListRequest, getReadyTasksList,
    setPlannedTaskRequest
} from "./requests";
import {turnOnViewerLoading, updateModelStateInReducer} from "../modelsReducer/actions";
import {getSingleModelTask, updateSimulationTaskStatus} from "../modelsReducer/requests";
import {sendGeneralMessage, setFirstTimeRendered, setRedirectPath} from "../layoutReducer/actions";
import {IDLE, MOUNTING} from "../../../components/StatusesComponets/statusConstants";
import {deinitializeStatusPage, setTaskStatus} from "../statusReducer/actions";
import {log} from "three/nodes";

function* getPlannedTasks() {

    try {
        const {data} = yield call(getPlannedTasksListRequest)
        let filteredData = []
        if (data && data.length) {
            filteredData = data
        }
        yield put(getPlannedTasksListSuccess(filteredData))


    } catch (e) {
        yield put(getPlannedTasksListFail())
        yield put(sendGeneralMessage("Произошла ошибка при загрузке заданий", 'error'))
    }
}
function* getReadyTasks(action) {
    try {
        const {data} = yield call(getReadyTasksList, action.filter)
        let filteredData = []
        if (data && data.length) {
            filteredData = data
        }
        yield put(getReadyTasksListSuccess(filteredData))
    } catch (e) {
        yield put(getReadyTasksListFail())
        yield put(sendGeneralMessage("Произошла ошибка при загрузке заданий", 'error'))
    }
}

function* setPlannedTask(action) {
    try {
        const {data} = yield call(setPlannedTaskRequest, action.selectedTaskName, action.count)
        console.log(data)
    } catch (e) {
        yield put(sendGeneralMessage("Ну удлось сделать запрос", 'error'))
    }
    finally {
        action.loadPlanned ? action.loadPlanned() : console.log('no need to load planned')
        action.handleClose ? action.handleClose() : console.log('no modal')
        action.reloadPage ? action.reloadPage() : console.log('no need to reload')
    }
}

function* getSingleTask(action) {
    yield put(turnOnViewerLoading())
    try {
        const {data} = yield call(getSingleModelTask, action.payload)
        const {readyTasksList} = yield select((state) => state.readyTasksReducer)
        const chosenItem = readyTasksList.find((item) => item.name === action.payload)
        const missedParts = chosenItem?.planning_info?.total_list_of_missed_parts ? chosenItem.planning_info.total_list_of_missed_parts : []
        yield put(setListOfMissedParts(missedParts))
        yield put(getSingleReadyTaskSuccess(data))
    } catch (e) {
        yield put(getSingleReadyTaskFail())
        yield put(sendGeneralMessage("Произошла ошибка при загрузке задания", 'error'))
    }
}


function* deleteTaskSaga(action) {
    try {
        yield call(updateSimulationTaskStatus, action.payload, 'Ready')
        yield put(taskDeleteSuccess(action.payload))
        yield put(setFirstTimeRendered())
        yield put(updateModelStateInReducer(action.payload, 'Ready'))

    } catch (e) {
        yield put(taskDeleteFail())
        yield put(sendGeneralMessage("Произошла ошибка при удалении задания", 'error'))
    }

}


function* submitProcessingTaskSaga() {
    try {
        const {selectedTask} = yield select((state) => state.readyTasksReducer)
        const newTask = {
            task_id: "",
            upload_time: "",
            assembly_name: selectedTask?.file?.name,
            statuses:{
                taskStatus: MOUNTING,
                scanningBeamStatus: IDLE,
                scanningPartsStatus: IDLE,
                labelingStatus: IDLE,
                executorStatus: IDLE,
                plannerStatus: IDLE,
            },
            scanning_info: {},
            selected_model: selectedTask,
            orientation_of_parts: Object.keys(selectedTask.parts).reduce((acc, item) => {
                acc[item] = true
                return acc
            }, {}),
            parts_to_reverse: {}
        };
        yield call(createPendingTask, newTask)
        yield put(submitProcessingTaskSuccess())
        yield put(setTaskStatus(MOUNTING))
        yield put(deinitializeStatusPage())
        yield put(sendGeneralMessage(`Модель ${selectedTask?.file?.name} была успешно отправлена в работу`, 'success'))
        yield put(setRedirectPath('/status'))

    } catch (e) {
        yield put(submitProcessingTaskFail())
        yield put(sendGeneralMessage("Произошла ошибка при загрузке задания", 'error'))
    }

}

export default function* readyTasksSaga() {
    yield takeLatest(GET_READY_TASKS_REQUEST, getReadyTasks)
    yield takeLatest(GET_PLANNED_TASKS_REQUEST, getPlannedTasks)
    yield takeLatest(GET_SINGLE_READY_TASK_REQUEST, getSingleTask)
    yield takeLatest(TASK_DELETE_MODEL_REQUEST, deleteTaskSaga)
    yield takeLatest(SUBMIT_TASK_PROCESSING_REQUEST, submitProcessingTaskSaga)
    yield takeLatest(SET_PLANNED_TASK_REQUEST, setPlannedTask)
}
