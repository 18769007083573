import React, {Suspense} from 'react';
import CameraControls from "../../CameraControls/CameraControls";
import DeskViewerSingleModel from "../DeskViewerSingleModel/DeskViewerSingleModel";
import {useThree} from "@react-three/fiber";

function DeskViewerScene({geomList, showId}) {
  return (
      <>
          <Suspense fallback={null}>
              <DeskViewerSingleModel geomList={geomList} showId={showId}/>
          </Suspense>
          <CameraControls/>
          <directionalLight
              intensity={0.2}
              castShadow
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
          />
      </>
  );
}

export default DeskViewerScene;
