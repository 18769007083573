import {authAxios} from "../../../settingsFiles/axiosSettings";

export const getArchiveTasksListRequest = (filter) => {
    return authAxios.post("tasks/archive", {...filter})
}
export const getGraphDataApiRequest = (dates) => {
    return authAxios.post("graph/assembled_count", {...dates})
}
export const getGraphExcelApiRequest = (dates) => {
    return authAxios.post("graph/assembled_count/export_excel", {...dates}, {responseType: 'blob'})
}
