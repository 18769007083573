import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GeneralTable2 from "../../components/Tables/GeneralTable/GeneralTable2";
import Columns from "./Columns";
import {selectArchivedTask} from "../../redux/reducers/archiveReducer/actions";
import {getSingleModelRequest} from "../../redux/reducers/modelsReducer/actions";

function ArchiveTable({filter, changeFilter}) {

  const headers = ['CadModelHeader', 'RotationHeader', 'AssembleStarted', 'EfficientTime', 'OverallAssembleTime']
  const {archiveTasksList,archiveTasksAreLoading} = useSelector((state => state.archiveReducer))
  const {selectedModelName} = useSelector((state => state.modelsReducer))
  const {rows}  = Columns(archiveTasksList)
  const dispatch = useDispatch()
  const onRowClick = (id, model_name) => {
    dispatch(selectArchivedTask(id))
    dispatch(getSingleModelRequest(model_name))
  }
  return <GeneralTable2 headers={headers} rows={rows} onRowClick={onRowClick} tableIsLoading={archiveTasksAreLoading}
          filter={filter} changeFilter={changeFilter} activeItem={selectedModelName}/>
}

export default ArchiveTable;
