import React, {Suspense, useEffect} from 'react';
import DeskViewerModels from "./DeskViewerModels";
import CameraControls from "../../../components/ThreeJsComponents/CameraControls/CameraControls";

function DeskViewerScene({geomList, showId}) {
  return (
    <>
      <Suspense fallback={null}>
        <DeskViewerModels geomList={geomList} showId={showId}/>
      </Suspense>
      <CameraControls/>
      <directionalLight
        intensity={0.2}
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
      />
    </>
  );
}

export default DeskViewerScene;
