import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import rack2 from '../../assets/images/racks2.png'
import rack3 from '../../assets/images/racks3.png'
import RackTextContainer from "./RackTextContainer";


function Rack({screenMode =false}) {


    const {
        rackParts: {
            beam_offset,
            holder3_is_used,
            holder2_position,
            holder3_position,
            holder1_offset,
            holder2_offset,
            holder3_offset
        }
    }
        = useSelector(state => state.statusReducer)



    const holderPositions = {
        beam_offset: {
            style: {
                rack3: {left: '80.9%', bottom: '65%'},
                rack2: {left: '81.1%', bottom: '65%'},
            },
            value: beam_offset
        },
        holder2_position: {
            style: {
                rack3: {left: '47.7%', bottom: '71.5%'},
                rack2: {left: '47.4%', bottom: '71.5%'}
            },
            value: holder2_position
        },
        holder3_position: {
            style: {
                rack3: {left: '17.6%', bottom: '71.5%'},
                rack2:  {left: '16.7%', bottom: '71.5%'},
            },
            value: holder3_position
        },
        holder1_offset: {
            style: {
                rack3: {right: '22%', bottom: '34%'},
                rack2:  {right: '22%', bottom: '34%'},
            },
            value: holder1_offset
        },
        holder2_offset: {
            style: {
                rack2: {right: '52.7%', bottom: '34%'},
            },
            value: holder2_offset
        },
        holder3_offset: {
            style: {
                rack3: {right: '82.4%', bottom: '34%'},
            },
            value: holder3_offset
        },
        holder1_number:{
            style: {
                rack3: {right: '20.2%', bottom: '8%'},
                rack2:  {right: '20.2%', bottom: '8%'},
            },
            value: 1
        },
        holder2_number:{
            style: {
                rack3: {right: '50.4%', bottom: '8%'},
                rack2:  {right: '50.7%', bottom: '8%'},
            },
            value: 2
        },
        holder3_number:{
            style: {
                rack3: {right: '80.6%', bottom: '8%'},
                rack2:  {right: '81.5%', bottom: '8%'},
            },
            value: 3
        }

    }

    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <img src={holder3_is_used ? rack3 : rack2} alt={'ok'}
                 style={{width: '100%', height: '100%', borderRadius: '10px'}}/>
            {Object.entries(holderPositions).map(([key, value]) => {
                if ((key === 'holder2_offset' && holder3_is_used) || (key === 'holder3_offset' && !holder3_is_used)) {
                    return <Fragment key={key}/>
                }
                return <RackTextContainer key={key} position={holder3_is_used ? value.style.rack3 : value.style.rack2}
                                          screenMode={screenMode}
                                          text={value.value} positionName={key}/>
            })}
        </div>
    );
}

export default Rack;

