import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSimulationTaskRequest} from "../../redux/reducers/modelsReducer/actions";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import {Grid} from "@mui/material";
import ModelsTable from "../../components/Tables/ModelsTable/ModelsTable";
import ModelsButtons from "../../components/ButtonsGroup/ModelsButton/ModelsButtons";
import useTableFilter from "../../utils/useTableFilter";
import PartsModal from "./PartsModal";

function ModelsPage({isMobile}) {

    const {
        selectedModel,
        missedPartsList,
    } = useSelector((state) => state.modelsReducer)
    const dispatch = useDispatch()
    const loadData = (filter) => dispatch(getSimulationTaskRequest(filter));
    const {filter, changeFilter} = useTableFilter(loadData, {
        sortField: 'sort_date',
        sortOrder: 'desc',
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Grid container style={{width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '40%' : 'initial'}}>
                <ModelsTable isMobile={isMobile} filter={filter} changeFilter={changeFilter}/>
            </Grid>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '50%' : 'initial'}}>
                <div style={{height: isMobile ? '100%' : '74vh'}}>
                    <Viewer3DContainer selectedModel={selectedModel} missedPartsList={missedPartsList} page={"models"}
                    isMobile={isMobile}/>
                </div>
                <div style={{height: isMobile ? '50px' : '8vh',display:'flex',alignItems:'center'}}>
                    <ModelsButtons isMobile={isMobile} filter={filter} handleOpen={handleOpen}/>
                </div>
            </Grid>
            <PartsModal open={open} handleClose={handleClose}/>
        </Grid>
    );
}

export default ModelsPage;
