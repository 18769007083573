import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    ENG: {
        translation: {
            Part: 'Part',
            Quantity: 'Quantity',
            openParts: 'Show parts',
            total_parts: 'Total assembled parts',
            status: 'Status',
            general_view: 'General view',
            virtual_view: '3D view',
            scheme: 'Scheme',
            succ_end_but: 'Assembly successfully',
            cancel_end_but: 'Assembly not successfull',
            assembly_ended_modal_text: 'Assembly ended. Please select action',
            weight: 'weight',
            Total_weight: 'Total weight',
            kg: 'kg',
            Total_assembled: 'Total assembled',
            show: 'Show',
            download: 'Download',
            From_date: 'From date',
            To_date: 'to date',
            filter: 'Filter',
            graph3_title: 'Efficiency of RTK operation, % (main minutes/480)',
            graph2_title: 'Number of main minutes of RTC operation, min/day',
            graph1_title: 'Weights and number of assembled beams, pcs/day',
            CurrentAssemblyCount: 'Current assembly number',
            AssemblyCountChangeTo: 'Assembly count change to',
            ModelName: 'Model name',
            AddToPlan: 'Add To Plan',
            AssemblyCount: 'Assembly number',
            AssemblyPlan: 'Assembly Plan',
            EfficientTime: 'Efficient assembly time',
            PartsLoadingText: 'Downloading parts...',
            DownloadParts: 'Downloading parts',
            RetryCounts: 'Retry count',
            ProcessingTimeRetry: 'Processing time after last retry',
            ProcessingTime: 'Processing time',
            BeamScan: 'Beam scan',
            PartsScan: 'Parts scan',
            PlannerTask: 'Planner task',
            ExecutorTask: 'Executor task',
            AfterTurn: 'After turn',
            TurnCount: 'Total number of turns',
            AssembleStarted: 'AssembleStarted',
            days: 'days',
            hours: 'hours',
            minutes: 'minutes',
            seconds: 'seconds',
            OverallAssembleTime: 'Overall assembling time',
            Archive: 'Archive',
            SCANNING_BEAM_SUCCESS:  'Scan beam has been successfully ended.',
            unplug_scanner: 'Unplug the scanner!',
            all_parts: 'All parts',
            planned_parts: 'Planned parts',
            assembled_parts: 'Assembled parts',
            ScanResult: 'RESULTS OF SCANNING AND MATCHING',
            m: 'm',
            mm: 'mm',
            allChecked: 'all checked',
            beam_offset: 'Beam offset',
            holder2_position: 'Holder 2 position',
            holder3_position: 'Holder 3 position',
            holder1_offset: 'Holder 1 offset',
            holder2_offset: 'Holder 2 offset',
            holder3_offset: 'Holder 3 offset',
            Close: 'Close',
            GeneralSettings: "General Settings",
            ShowId: "Show ID",
            Models: 'Models',
            Tasks: 'Tasks',
            Status: 'Status',
            Service: 'Service',
            Settings: 'Settings',
            StatusHeader: 'Status header',
            DownloadTimeHeader: 'Upload Time',
            CadModelHeader: 'СAD Model',
            WillBeAssembledHeader: 'Will Be Assembled',
            DetailsNumber: 'Details number',
            NumberOfTurns: 'Number of turns',
            OutOf: 'out of',
            PleasePressOnModel: 'Model Viewer',
            BeamAssemblingInProcess: 'Beam assembling in process',
            SimulateButton: 'Simulate Button',
            SimulationInProcessButton: 'Simulation in process button',
            StatusInProcess: 'Status In Process',
            SubmitTaskButton: 'Submit Task Button',
            DeleteButton: 'Delete',
            ViewerTopView: 'Viewer Top View',
            ViewerFrontView: 'Viewer Front View',
            ViewerSideView: 'Viewer Side View',
            racksAreEstablished: 'Racks are established',
            beamIsEstablished: 'Beam is Established',
            beamIsRotated: 'Beam is Rotated',
            detailsAreEstablished: 'Details are established',
            LaunchButton: 'Launch',
            TablesNumber: 'Tables number',
            SCANNING_PARTS: 'SCANNING_PARTS',
            SCANNING_BEAM: 'Scanning beam',
            Revert: "Revert",
            AssemblyInProcess: 'Assembly in process',
            ScanningError: "Scanning error",
            ReadyToAssembly: 'Ready to assembly',
            AssemblyInProcessSuccess: "Assembly in process success",
            ScanningParts2: 'Scanning Parts 2',
            CheckingRevertedParts: "Checking reverted parts",
            NoTasksToBeAssembled: 'No tasks to be assembled',
            DeleteProcessingTask: 'Delete Task',
            Interrupted: 'Interrupted',
            StopAssembling: 'Stop Assembling',
            MakeReScan: 'Make ReScan',
            GettingInitialInformation: 'Getting Initial Information',
            SocketConnectionError: "Socket Connection Error",
            DetectedLessParts: 'Detected Less Parts',
            ReverseStep: 'Reverse Step',
            ReverseStep2: 'Reverse Step 2',
            ContinueAssembling: 'Continue Assembling',
            settingsModalHeader: 'Settings Modal Header',
            partsType: 'Parts type',
            viewerVisibility: 'Viewer visibility',
            viewerwireframe: 'Viewer wireframe',
            viewermaterialType: 'Viewer material type',
            viewerOpacity: 'Viewer opacity',
            viewermetalness: 'Viewer metalness',
            viewercolor: 'Viewer color',
            parts: 'Parts',
            missedParts: 'Missed parts',
            standardMesh: 'Standard mesh',
            physicalMesh: 'Physical mesh',
            normalMesh: 'Normal mesh',
            basicMesh: 'Basic mesh',
            depthMesh: 'Depth mesh',
            lambertMesh: 'Lambert mesh',
            matcapMesh: 'Matcap mesh',
            phongMesh: 'Phong mesh',
            toonMesh: 'Toon mesh',
            RotationHeader: 'Rotation',
            CameraTopView: 'Camera Top View',
            RackView: 'Rack View',
            CameraSideView: 'Camera Side View',
            CameraFrontView: 'Camera Front View',
            SubmitTask: 'Submit Task',
            SCANNING_BEAM_ERROR: 'SCANNING_BEAM_ERROR',
            SCAN_REVERSE_PARTS: 'SCAN_REVERSE_PARTS',
            SCAN_LESS_PARTS: 'SCAN_LESS_PARTS',
            SCAN_UNMATCHED_PARTS: 'SCAN_UNMATCHED_PARTS',
            SCANNING_PARTS_ERROR: 'SCANNING_PARTS_ERROR',
            STARTING_PLANNER_AND_LABEL: 'STARTING_PLANNER_AND_LABEL',
            PLANNER_IS_RUNNING: 'PLANNER_IS_RUNNING',
            LABELING_IS_RUNNING: 'LABELING_IS_RUNNING',
            EXECUTOR_IDLE: 'EXECUTOR_IDLE',
            LABEL_ERROR: 'LABEL_ERROR',
            PLANNER_OK: 'PLANNER_OK',
            PLANNER_ERROR: 'PLANNER_ERROR',
            EXECUTOR_IS_RUNNING: 'EXECUTOR_IS_RUNNING',
            EXECUTOR_OK: 'EXECUTOR_OK',
            EXECUTOR_ERROR: 'EXECUTOR_ERROR',
            motorsOn:'MotorsOn',
            ppToMain:'pp2main',
            motorsStop:'Stop',
            parking: 'Parking',
            DeleteButtonTask:'Delete A Task',
            Camera:'Camera',
            StatusSettings:'Status Settings',
            TableStatusInput:'Table Status Input',
            archiveButton:'Archive a Task',
            robotError:'robotError',
            SCANNING_BEAM_ROBOT_ERROR:'SCANNING_BEAM_ROBOT_ERROR',
            SCANNING_PARTS_ROBOT_ERROR:'SCANNING_PARTS_ROBOT_ERROR',
            LABELING_ROBOT_ERROR:'LABELING_ROBOT_ERROR',
            EXECUTOR_ROBOT_ERROR:'EXECUTOR_ROBOT_ERROR',
        }
    },
    RUS: {
        translation: {
            Part: 'Деталь',
            Quantity: 'Количество',
            openParts: 'Показать детали',
            total_parts: 'Всего собрано деталей',
            status: 'Статус',
            general_view: 'Общий вид',
            virtual_view: '3D вид',
            scheme: 'Чертеж',
            succ_end_but: 'Сборка успешна',
            cancel_end_but: 'Сборка не успешна',
            assembly_ended_modal_text: 'Сборка завершена. Выберите действие',
            weight: 'вес',
            Total_weight: 'Общий вес',
            kg: 'кг',
            Total_assembled: 'Всего сборок',
            show: 'Посмотреть',
            download: 'Загрузить',
            From_date: 'С даты',
            To_date: 'до даты',
            filter: 'Фильтр',
            graph3_title: 'Эффективность работы РТК, %(осн. минуты/480)',
            graph2_title: 'Количество основных минут работы РТК, мин/день',
            graph1_title: 'Вес сборок и количество собранных балок, шт/день',
            CurrentAssemblyCount: 'Текущее количество сборок',
            AssemblyCountChangeTo: 'Изменить количество сборок на',
            ModelName: 'Название модели',
            AddToPlan: 'Добавить в план',
            AssemblyCount: 'Количество сборок',
            AssemblyPlan: 'План сборки',
            EfficientTime: 'Полезное время сборки',
            PartsLoadingText: 'Загрузка деталей...',
            DownloadParts: 'Загрузка деталей',
            RetryCounts: 'Количество повторов',
            ProcessingTimeRetry: 'Время обработки после последнего повтора',
            ProcessingTime: 'Время обработки',
            BeamScan: 'Сканировние балки',
            PartsScan: 'Сканирование деталей',
            PlannerTask: 'Планнер',
            ExecutorTask: 'Сборка',
            AfterTurn: 'После поворота',
            TurnCount: 'Количество поворотов',
            AssembleStarted: 'Начало сборки',
            days: 'день',
            hours: 'час',
            minutes: 'минут',
            seconds: 'секунд',
            OverallAssembleTime: 'Общее время сборки',
            Archive: 'Архив',
            SCANNING_BEAM_SUCCESS: 'Сканер двутавра закончил работу',
            unplug_scanner: 'Отсоедини сканер!',
            all_parts: 'Всего деталей',
            planned_parts: 'Запланированы детали',
            assembled_parts: 'Собрано деталей',
            ScanResult: 'РЕЗУЛЬТАТ СКАНА И МАТЧИНГА',
            m: 'м',
            mm: 'мм',
            allChecked: 'все проверил',
            beam_offset: 'Вылет балки',
            holder2_position: 'Второй козлик на',
            holder3_position: 'Третий козлик на',
            holder1_offset: 'Отступ на первом',
            holder2_offset: 'Отступ на втором',
            holder3_offset: 'Отступ на третьем',
            Close: 'Закрыть',
            GeneralSettings: "Общие настройки",
            ShowId: "Показать ID",
            DeleteButtonTask:'Отменить задачу',
            Models: 'Модели',
            Tasks: 'Задания',
            Status: 'В работе',
            Service: 'Сервис',
            Settings: 'Настройки',
            StatusHeader: 'Статус',
            DownloadTimeHeader: 'Время загрузки',
            CadModelHeader: 'Модель',
            WillBeAssembledHeader: 'Будет собрано',
            DetailsNumber: 'Деталей',
            NumberOfTurns: 'Поворотов',
            OutOf: 'из',
            PleasePressOnModel: 'Нажмите на модель',
            BeamAssemblingInProcess: 'Идет процесс сбора балки',
            SimulateButton: 'Симулировать',
            SimulationInProcessButton: 'Идет симуляция',
            StatusInProcess: 'Идет сборка модели',
            SubmitTaskButton: 'Переместить в задания',
            DeleteButton: 'Удалить модель',
            ViewerTopView: 'Вид сверху',
            ViewerFrontView: 'Вид спереди',
            ViewerSideView: 'Вид сбоку',
            racksAreEstablished: 'Козлики установлены',
            beamIsEstablished: 'Двутавр выставлен',
            detailsAreEstablished: 'Детали выложены',
            LaunchButton: 'Запустить',
            TablesNumber: 'Кол-во столов',
            SCANNING_PARTS: 'Выполняется: Сканирование деталей...',
            SCANNING_PARTS_IS_RUNNING: 'Выполняется: Сканирование деталей...',
            SCANNING_BEAM: 'Выполняется: Сканирование двутавра...',
            SCANNING_BEAM_IS_RUNNING: 'Выполняется: Сканирование двутавра...',
            Revert: "Внимание! Необходимо перевернуть красные детали.",
            AssemblyInProcess: 'Выполняется: Расчеты траекторий и сборка...',
            ScanningError: "Внимание! Ошибка сканирования, повторите попытку!",
            ReadyToAssembly: 'Балка готова для сборки',
            AssemblyInProcessSuccess: "Выполняется: Расчеты траекторий и сборка...",
            ScanningParts2: 'Выполняется: Повторное сканирование..',
            CheckingRevertedParts: "Выполняется: Проверка деталей...",
            NoTasksToBeAssembled: 'Нет заданий для обработки',
            Interrupted: 'Сборка была прервана',
            StopAssembling: 'Остановить сборку',
            MakeReScan: 'Повторить',
            GETTING_INITIAL_INFORMATION: 'Получаем статус...',
            SOCKET_CONNECTION_ERROR: "Ошибка подключения к серверу",


            DetectedLessParts: 'Некоторые части не были найдены',
            ReverseStep: 'ПОВЕРНИТЕ ДВУТАВР НА 180 ГРАДУСОВ',
            ReverseStep2: 'Шаг 2 Проверить отступы и вылет - вид сверху',
            beamIsRotated: 'Двутавр повернул',
            ContinueAssembling: 'Продолжить сборку',
            settingsModalHeader: 'Настройки 3Д Модели',
            partsType: 'Показать Допдаун меню',
            viewerVisibility: 'Видимость',
            viewerwireframe: 'Отображать только каркас',
            viewermaterialType: 'Тип Материала',
            viewerOpacity: 'Прозрачность',
            viewermetalness: 'Металличность',
            viewercolor: 'Цвет',
            parts: 'Все',
            missedParts: 'Пропущенные детали',
            standardMesh: 'Стандартный',
            physicalMesh: 'Физический',
            normalMesh: 'Нормальный',
            basicMesh: 'Базовый',
            depthMesh: 'Глубокий',
            lambertMesh: 'Ламберт',
            matcapMesh: 'Матовый',
            phongMesh: 'Затененный',
            toonMesh: 'Мультяшный',
            RotationHeader: 'Поворотов',
            DeleteProcessingTask: 'Отменить задание',
            CameraTopView: 'ВИД СВЕРХУ',
            RackView: 'ОБЩИЙ ВИД',
            CameraSideView: 'ВИД СБОКУ',
            CameraFrontView: 'ВИД СПЕРЕДИ',
            TableWarning: 'Внимание между столами не должно быть пробелов',
            SubmitTask: 'Взять задание',
            SCANNING_BEAM_ERROR: 'Ошибка сканирования двутавра',
            SCAN_REVERSE_PARTS: 'Переверните детали красного цвета',
            SCANNING_REVERSE_PARTS_ERROR: 'Переверните детали красного цвета',
            SCAN_LESS_PARTS: 'Не хватает деталей',
            SCANNING_LESS_PARTS_ERROR: 'Не хватает деталей',
            SCAN_UNMATCHED_PARTS: 'Кол-во деталей не совпадает',
            SCANNING_UNMATCHED_PARTS_ERROR: 'Кол-во деталей не совпадает',
            SCANNING_PARTS_ERROR: 'Ошибка сканирования деталей',
            STARTING_PLANNER_AND_LABEL: 'Расчет траекторий и клеймо',
            PLANNER_IS_RUNNING: 'Планер находится в работе',
            LABELING_IS_RUNNING: 'Ставим клеймо',
            EXECUTOR_IDLE: 'EXECUTOR_IDLE',
            LABEL_ERROR: 'Ошибка с клеймом',
            LABELING_ERROR: 'Ошибка с клеймом',
            PLANNER_OK: 'Планер закончил работу',
            PLANNER_SUCCESS: 'Планер закончил работу',
            PLANNER_ERROR: 'Ошибка с планером',
            EXECUTOR_IS_RUNNING: 'Идет процесс сборки',
            EXECUTOR_OK: 'Сборка завершена',
            EXECUTOR_SUCCESS: 'Сборка завершена',
            EXECUTOR_ERROR: 'Ошибка при сборке',
            motorsOn:'MotorsOn',
            ppToMain:'pp2main',
            motorsStop:'Stop',
            parking: 'Parking',
            Camera:'Камера',
            StatusSettings:'Чек-лист:',
            TableStatusInput:'Кол-во столов с деталями',
            archiveButton:'Архивировать',
            robotError:'Робот не может продолжить сборку',
            SCANNING_BEAM_ROBOT_ERROR:'Случилась ошибка с роботом',
            SCANNING_PARTS_ROBOT_ERROR:'Случилась ошибка с роботом',
            LABELING_ROBOT_ERROR:'Случилась ошибка с роботом',
            EXECUTOR_ROBOT_ERROR:'Случилась ошибка с роботом',
        }
    }
}


const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'RUS'
i18n.use(initReactI18next).init({
    resources,
    lng: currentLanguage,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
