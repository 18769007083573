import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deleteModelRequest, startPreAssembly} from "../../../redux/reducers/modelsReducer/actions";
import ServiceButton from "../../ServiceButton/ServiceButton";


function ModelsButtons({isMobile, filter, handleOpen}) {

    const {
        modelsButtonsState,
        modelInstance,
        modelIsDeleting
    } = useSelector((state) => state.modelsReducer)
    const dispatch = useDispatch()

    const buttonFunctions = {
        showPartsButton: <ServiceButton variant={'outlined'}
                                          color={'secondary'}
                                          width={165}
                                          buttonName={'openParts'}
          // loadingCondition={updatingModelStatus}
                                          onClick={() => {handleOpen()}}
                                          style={{marginLeft: '20px'}}/>,
        showSimulationBtn: <ServiceButton variant={'outlined'}
                                          color={'secondary'}
                                          width={145}
                                          buttonName={'SimulateButton'}
                                          // loadingCondition={updatingModelStatus}
                                          onClick={() => dispatch(startPreAssembly(modelInstance.name, filter))}
                                          style={{marginLeft: '20px'}}/>,
        // showSimulationBtn: null,

        // showSpinnerBtn: <div style={{display: 'flex', alignItems: 'center'}}>
        //     <CircularProgress
        //         color={'secondary'}
        //         style={{marginLeft: '20px'}}/><Typography color={'white'}> {t('SimulationInProcessButton')}</Typography>
        // </div>,
        showSpinnerBtn: null,

        // showSaveTaskBtn: <ServiceButton variant={'contained'}
        //                                 color={'secondary'}
        //                                 width={250}
        //                                 buttonName={'SubmitTaskButton'}
        //                                 loadingCondition={updatingModelStatus}
        //                                 onClick={() => dispatch(updateSimulationTaskStatus(modelInstance.name))}
        //                                 style={{marginLeft: '20px'}}
        // />,
        showSaveTaskBtn: null,


        showDeleteBtn: <ServiceButton variant={'outlined'}
                                      color={'error'}
                                      width={isMobile ? 130 : 160}
                                      height={isMobile ? 30 : 40}
                                      buttonName={'DeleteButton'}
                                      loadingCondition={modelIsDeleting}
                                      onClick={() => dispatch(deleteModelRequest(modelInstance.name))}
                                      style={{marginLeft: '20px'}}/>,
    }


    return (
        <div>


            {Object.entries(modelsButtonsState).map(([key, value]) => {
                if (value) {
                    return <Fragment key={key}>{buttonFunctions[key]}</Fragment>
                } else {
                    return <Fragment key={key}/>
                }
            })}
        </div>
    );
}

export default ModelsButtons;
