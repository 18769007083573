import React from 'react';

import Viewer3DLoader from "./Viewer3DLoader/Viewer3DLoader";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useStyles, {useStylesMobile} from "./Viewer3DLoader/Viewer3DLoaderStyle";
import {CircularProgress, Typography} from "@mui/material";
import ErrorBoundary from "../../LogicalComponents/ErrorBoundary/ErrorBoundary";


function Viewer3DContainer({selectedModel, missedPartsList, page, selectedModelName, isMobile, height}) {

    const {t} = useTranslation()
    const {viewerIsLoading, viewerFirstTimeRendered} = useSelector((state) => state.layoutReducer)
    const classes = useStyles({viewerIsLoading, viewerFirstTimeRendered})
    const mobileClasses = useStylesMobile({viewerIsLoading, viewerFirstTimeRendered})
    const {singleModelIsLoading} = useSelector((state) => state.modelsReducer)


    const loaderElem = viewerFirstTimeRendered ?
        <Typography style={{color: 'white'}}> {t('PleasePressOnModel')}</Typography> :
        <CircularProgress size={80} style={{'color': 'black'}}/>
    return <>
        <div className={isMobile ? mobileClasses.container : classes.container} style={height && {height: '50%'}}>
            <ErrorBoundary message={'Что-то пошло не так'}>
                {selectedModel ? <Viewer3DLoader selectedModel={selectedModel} missedPartsList={missedPartsList}
                                                 selectedModelName={selectedModelName} page={page}/> : null}
                {singleModelIsLoading && <div className={classes.loaderContainer} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                    {loaderElem}
                </div>}
            </ErrorBoundary>
        </div>
    </>


}

export default Viewer3DContainer;
