import React from 'react';
import {Typography} from "@mui/material";
import AssembledCounter from "../../ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import PlannedCounter from "../../ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";

const UnknownStatus = ({isMobile, executor, planner}) => {
    return (
        <div style={isMobile? {textAlign: 'center', height: '100%', flexDirection: 'column'} :
          {height: '80vh', flexDirection: 'column'}} className={'globalCentered'}>
            <Typography color={'white'} fontSize={isMobile ? 20 : 50}> Упс! незнакомый статус</Typography>
          {executor && <AssembledCounter/>}
          {planner && <PlannedCounter/>}
        </div>
    );
};

export default UnknownStatus;
