import React from 'react';
import ScreensComponent from "../../components/ScreensComponent/ScreensComponent";
import StatusSocket from "../../components/StatusesComponets/StatusSocket/StatusSocket";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";

function Screen1Page() {


    return (
        <ErrorBoundary message={'Что-то пошло нет'}>
            <StatusSocket/>
            <ScreensComponent/>
        </ErrorBoundary>
    );
}

export default Screen1Page;