import React, {useState} from 'react';
import {Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {makeStyles} from "@mui/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import UpdateIcon from '@mui/icons-material/Update';
import ArticleIcon from '@mui/icons-material/Article';
import CustomLink from "../LogicalComponents/Routes/CustomLink";
import ArchiveIcon from '@mui/icons-material/Archive';
import TaskIcon from '@mui/icons-material/Task';
import BarChartIcon from '@mui/icons-material/BarChart';


const useStyles = makeStyles(theme => ({

    iconColor: {
        color: '#3C3C3C'
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingLeft: '13%',
        position: 'relative',
        height: '45px',
        // marginBottom:'20px',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    spanItem: {
        position: "absolute",
        top: 0,
        left: 0,
        background: '#FF003B',
        height: '100%',
        width: '3%',
    },
    listHeader: {
        color: '#FF003B !important',
        display: 'flex',
        paddingLeft: '15%',
        marginBottom: '20px'
    },
    listLabel: {color: 'white'}
}))


const LinksItems = () => {


    const [renderNav] = useState(true)
    const iconStyle = {color: 'white', fontSize: '18px',marginBottom: '3px'}
    const {t} = useTranslation();
    const {chosenSection} = useSelector((state) => state.cachedReducer)
    const classes = useStyles()

    const navLinks = [
        {name: 'Models', link: '/', icon: <BusinessCenterIcon style={iconStyle}/>},
        {name: 'Tasks', link: '/tasks', icon: <ArticleIcon style={iconStyle}/>},
        {name: 'AssemblyPlan', link: '/assembly_plan', icon: <TaskIcon style={iconStyle}/>},
        {name: 'Status', link: '/status', icon: <UpdateIcon style={iconStyle}/>},
        {name: 'Service', link: '/service', icon: <SettingsIcon style={iconStyle}/>},
        {name: 'Archive', link: '/archive', icon: <ArchiveIcon style={iconStyle}/>},
        {name: 'OEE', link: '/oee', icon: <BarChartIcon style={iconStyle}/>},
        // {name: 'Camera', link: '/stream_page', icon: <LiveTvIcon style={iconStyle}/>},
    ]

    return (
        renderNav &&

        <div>
            {navLinks.map((item) => {

                return <CustomLink to={item.link}  key={item.name}>
                    <div
                        style={chosenSection === item.link ? {background: 'linear-gradient(90deg, #242424 0%, #141414 100%)'} : null}
                        className={classes.listItem}
                    >

                        {chosenSection ===  item.link && <span className={classes.spanItem}/>}
                        <div style={{display:'flex',alignItems:'center'}}>
                        {item.icon}
                        <Typography className={classes.listLabel} style={{marginLeft: '15px', fontSize: '18px'}}> {t(item.name)}</Typography>
                        </div>
                    </div>
                </CustomLink>


            })}
        </div>


    )
};

// <Link to={item.link} style={{textDecoration: 'none'}}>
//     <ListItem onClick={() => dispatch(setChosenNavTab(item.name))} >
//         <ListItemIcon style={{color: '#3C3C3C'}}>
//             <BusinessCenterIcon/>
//         </ListItemIcon>
//         <ListItemText style={{color: 'white', marginLeft: '-20px'}}> {t(item.name)}</ListItemText>
//     </ListItem>
// </Link>

export default LinksItems;
