import React, {Suspense, useRef} from "react";
import {Canvas, useFrame} from "@react-three/fiber";
import {useSelector} from "react-redux";
import MeshMaterial from "../ThreeJsComponents/MeshMaterial/MeshMaterial";

import {Plane} from "@react-three/drei"


function Box({viewerSettings, partsType, ...props}) {
    const mesh = useRef();
    const {parts, missedParts} = viewerSettings
    const settings = partsType === 'missedParts' ? missedParts : parts

    useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.008));
    return (
        <Suspense fallback={true}>
            <mesh {...props} ref={mesh} castShadow receiveShadow>
                <boxGeometry args={[3, 3, 3]}/>
                <MeshMaterial
                    type={settings.materialType}
                    attach="material"
                    color={settings.color}
                    transparent={true}
                    opacity={settings.opacity}
                    wireframe={settings.wireframe}
                />
            </mesh>

        </Suspense>
    );
}

export default function ExampleModel() {

    const {viewerSettings, partsType} = useSelector((state) => state.cachedReducer)
    return (
        <Canvas shadows dpr={[1, 2]} performance={{min: 0.1}}>
            <ambientLight intensity={0.2}/>
            <spotLight intensity={0.3} angle={0.3} penumbra={1} position={[5, 25, 20]} shadow-bias={-0.0001}
                       castShadow/>

            <Box position={[0, 0.7, 0]} viewerSettings={viewerSettings} partsType={partsType}/>
            <Plane
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -5, 0]}
                args={[1000, 1000]}
            >
                <meshStandardMaterial attach="material" color="white"/>
            </Plane>
        </Canvas>
    );
}