import React from 'react';
import {
    EXECUTOR_AND_PLANNING_ACTION,
    EXECUTOR_ERROR,
    EXECUTOR_IS_RUNNING, EXECUTOR_ROBOT_ERROR,
    EXECUTOR_SUCCESS,
    IDLE,
    LABELING_AND_PLANNING_ACTION,
    LABELING_ERROR,
    LABELING_IS_RUNNING, LABELING_ROBOT_ERROR,
    LABELING_SUCCESS,
    MOUNTING,
    PLANNER_ERROR,
    PLANNER_IS_RUNNING,
    PLANNER_SUCCESS,
    SCANNING_BEAM_ACTION,
    SCANNING_BEAM_ERROR,
    SCANNING_BEAM_IS_RUNNING, SCANNING_BEAM_ROBOT_ERROR,
    SCANNING_BEAM_SUCCESS,
    SCANNING_LESS_PARTS_ERROR,
    SCANNING_PARTS_ACTION,
    SCANNING_PARTS_ERROR,
    SCANNING_PARTS_IS_RUNNING, SCANNING_PARTS_ROBOT_ERROR,
    SCANNING_PARTS_SUCCESS,
    SCANNING_REVERSE_PARTS_ERROR,
    SCANNING_UNMATCHED_PARTS_ERROR,
    TURN_BEAM
} from "../StatusesComponets/statusConstants";
import io from "socket.io-client";
import {Button} from "@mui/material";


const socket = io.connect(process.env.REACT_APP_SOCKET_URI, {
    path: "/ws/socket.io",
    transports: ['websocket', 'polling']
})


const statusesObjects = {
    IDLE: {
        task_status: IDLE,
        scanning_beam_status: IDLE,
        scanning_parts_status: IDLE,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    MOUNTING: {

        task_status: MOUNTING,
        scanning_beam_status: IDLE,
        scanning_parts_status: IDLE,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_BEAM_IS_RUNNING: {

        task_status: SCANNING_BEAM_ACTION,
        scanning_beam_status: SCANNING_BEAM_IS_RUNNING,
        scanning_parts_status: IDLE,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,

    },
    SCANNING_BEAM_ERROR: {

        task_status: SCANNING_BEAM_ACTION,
        scanning_beam_status: SCANNING_BEAM_ERROR,
        scanning_parts_status: IDLE,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_BEAM_ROBOT_ERROR:{
        task_status: SCANNING_BEAM_ACTION,
        scanning_beam_status: SCANNING_BEAM_ROBOT_ERROR,
        scanning_parts_status: IDLE,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_PARTS_IS_RUNNING: {
        task_status: SCANNING_PARTS_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_IS_RUNNING,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_PARTS_ERROR: {
        task_status: SCANNING_PARTS_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_ERROR,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_REVERSE_PARTS_ERROR: {
        task_status: SCANNING_PARTS_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_REVERSE_PARTS_ERROR,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_LESS_PARTS_ERROR: {
        task_status: SCANNING_PARTS_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_LESS_PARTS_ERROR,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_UNMATCHED_PARTS_ERROR: {
        task_status: SCANNING_PARTS_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_UNMATCHED_PARTS_ERROR,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    SCANNING_PARTS_ROBOT_ERROR:{
        task_status: SCANNING_PARTS_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_ROBOT_ERROR,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },
    PLANNER_IS_RUNNING: {
        task_status: LABELING_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_IS_RUNNING,
        executor_status: IDLE,
        planner_status: PLANNER_IS_RUNNING,
    },
    PLANNER_ERROR: {
        task_status: LABELING_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_IS_RUNNING,
        executor_status: IDLE,
        planner_status: PLANNER_ERROR,
    },
    PLANNER_SUCCESS: {
        task_status: LABELING_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_IS_RUNNING,
        executor_status: IDLE,
        planner_status: PLANNER_SUCCESS,
    },
    EXECUTOR_IS_RUNNING: {
        task_status: EXECUTOR_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_SUCCESS,
        executor_status: EXECUTOR_IS_RUNNING,
        planner_status: PLANNER_IS_RUNNING,
    },
    EXECUTOR_ERROR: {
        task_status: EXECUTOR_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_SUCCESS,
        executor_status: EXECUTOR_ERROR,
        planner_status: PLANNER_IS_RUNNING,
    },
    EXECUTOR_ROBOT_ERROR:{
        task_status: EXECUTOR_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_SUCCESS,
        executor_status: EXECUTOR_ROBOT_ERROR,
        planner_status: PLANNER_IS_RUNNING,
    },
    EXECUTOR_SUCCESS: {
        task_status: EXECUTOR_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_SUCCESS,
        executor_status: EXECUTOR_SUCCESS,
        planner_status: PLANNER_IS_RUNNING,
    },
    LABELING_IS_RUNNING: {
        task_status: LABELING_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_IS_RUNNING,
        executor_status: IDLE,
        planner_status: PLANNER_IS_RUNNING,
    },
    LABELING_ERROR: {
        task_status: LABELING_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_ERROR,
        executor_status: IDLE,
        planner_status: PLANNER_IS_RUNNING,
    },
    LABELING_ROBOT_ERROR:{
        task_status: LABELING_AND_PLANNING_ACTION,
        scanning_beam_status: SCANNING_BEAM_SUCCESS,
        scanning_parts_status: SCANNING_PARTS_SUCCESS,
        labeling_status: LABELING_ROBOT_ERROR,
        executor_status: IDLE,
        planner_status: PLANNER_IS_RUNNING,
    },
    TURN_BEAM:{
        task_status: TURN_BEAM,
        scanning_beam_status: IDLE,
        scanning_parts_status: IDLE,
        labeling_status: IDLE,
        executor_status: IDLE,
        planner_status: IDLE,
    },

}


function DebugComponent() {

    const sendStatus = (status) => {
        socket.emit('send_status', status)
    }
    const statuses = [IDLE,
        MOUNTING,
        SCANNING_BEAM_IS_RUNNING,
        SCANNING_BEAM_ERROR,
        SCANNING_BEAM_ROBOT_ERROR,
        SCANNING_PARTS_IS_RUNNING,
        SCANNING_PARTS_ERROR,
        SCANNING_REVERSE_PARTS_ERROR,
        SCANNING_LESS_PARTS_ERROR,
        SCANNING_UNMATCHED_PARTS_ERROR,
        SCANNING_PARTS_ROBOT_ERROR,
        PLANNER_IS_RUNNING,
        PLANNER_ERROR,
        PLANNER_SUCCESS,
        EXECUTOR_IS_RUNNING,
        EXECUTOR_ERROR,
        EXECUTOR_SUCCESS,
        EXECUTOR_ROBOT_ERROR,
        LABELING_IS_RUNNING,
        LABELING_ERROR,
        LABELING_ROBOT_ERROR,
        TURN_BEAM
    ]
    return <>{
        statuses.map((item) => {
            return <React.Fragment key={item}> <Button style={{fontSize: '11px'}} variant={'outlined'}
                                                       color={'secondary'} onClick={() => {
                sendStatus(statusesObjects[item])
            }}>
                {item}
            </Button>
                <br/>
            </React.Fragment>

        })
    }

    </>
}

export default DebugComponent;