import {call, put, select, takeLatest} from "redux-saga/effects";
import {
    DELETE_MODEL_REQUEST,
    GET_SIMULATION_TASKS_REQUESTS,
    GET_SINGLE_TASK_REQUEST,
    UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST,
    UPDATE_MODEL_SEND_TO_TASK_REQUEST
} from "./types";
import {
    deleteModelApi,
    getSimulationModelsTasks,
    getSingleModelTask,
    startRosSimulation,
    updateSimulationTaskStatus
} from "./requests";
import {
    deleteModelFail,
    deleteModelSuccess,
    getSimulationTaskFail,
    getSimulationTaskSuccess,
    getSingleModelFail,
    getSingleModelSuccess,
    setListOfMissedPartsModels,
    setSingleModelState,
    turnOnViewerLoading,
    updateModelStatusFail,
    updateSimulationTaskStatusFail,
    updateSimulationTaskStatusSuccess
} from "./actions";
import {getModelsButtonState} from "../../../utils/helperFunctions/buttonsState";
import {sendGeneralMessage, setFirstTimeRendered} from "../layoutReducer/actions";


function* getModels(action) {
    try {
        const {data} = yield call(getSimulationModelsTasks, action.filter)
        let filteredData = []
        if (data && data.length) { filteredData = data }
        yield put(getSimulationTaskSuccess(filteredData))
    } catch (e) {
        yield put(getSimulationTaskFail())
        yield put(sendGeneralMessage("Произошла ошибка при загрузке моделей", 'error'))
    }
}


function* getSingleModels(action) {

    const {simulationTasks} = yield select((state) => state.modelsReducer)
    const task = simulationTasks.find((item) => item.name === action.payload)
    yield put(setSingleModelState(task, getModelsButtonState(task?.status)))
    yield put(turnOnViewerLoading())
    try {
        const {data} = yield call(getSingleModelTask, action.payload)
        const missedParts = task?.planning_info?.total_list_of_missed_parts ? task.planning_info.total_list_of_missed_parts : []
        yield put(getSingleModelSuccess(data))
        yield put(setListOfMissedPartsModels(missedParts))
    } catch (e) {
        yield put(getSingleModelFail())
        yield put(sendGeneralMessage("Произошла ошибка при загрузке модели", 'error'))
    }
}


function* startPreAssemblySaga(action) {
    try {
        yield call(startRosSimulation, action.payload)
        yield call(getModels, action);
    } catch (e) {
        yield put(updateModelStatusFail())
        yield put(sendGeneralMessage("Произошла ошибка загрузки задач с БД.", 'error'))
    }
}


function* sendModelToTaskSaga(action) {
    try {
        yield call(updateSimulationTaskStatus, action.payload, 'Task')
        yield put(updateSimulationTaskStatusSuccess(action.payload))
    } catch (e) {
        yield put(updateSimulationTaskStatusFail())
        yield put(sendGeneralMessage("Произошла ошибка при смене статуса", 'error'))
    }

}

function* deleteModelSaga(action) {
    try {
        yield call(deleteModelApi, action.payload)
        yield put(deleteModelSuccess(action.payload))
        yield put(setFirstTimeRendered())
    } catch (e) {
        yield put(deleteModelFail(action.payload))
        yield put(sendGeneralMessage("Произошла ошибка при удалении", 'error'))

    }
}

export default function* modelsSaga() {
    yield takeLatest(GET_SIMULATION_TASKS_REQUESTS, getModels)
    yield takeLatest(GET_SINGLE_TASK_REQUEST, getSingleModels)
    yield takeLatest(UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST, startPreAssemblySaga)
    yield takeLatest(UPDATE_MODEL_SEND_TO_TASK_REQUEST, sendModelToTaskSaga)
    yield takeLatest(DELETE_MODEL_REQUEST, deleteModelSaga)
}
