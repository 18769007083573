import React from 'react';
import {Typography} from "@mui/material";

const RackTextContainer = ({text, position, positionName, screenMode}) => {
    const getStyle = (positionName) => {
        switch (positionName) {
            case 'holder3_position':
            case 'holder2_position':
                return {
                    border: '1px solid red',
                    width: '2.7%', height: 0,
                    textAlign: 'center',
                    borderRadius: '4px', paddingBottom: '2.5%'
                }
            case 'beam_offset':
                return {
                    borderLeft: '1px dashed red',
                    borderRight: '1px dashed red',
                    width: '13.4%', height: 0,
                    textAlign: 'center',
                    paddingBottom: '4%'
                }
            case 'holder1_offset':
            case 'holder2_offset':
            case 'holder3_offset':
                return {borderBottom: '1px dashed red', width: '5%', textAlign: 'left', paddingBottom: '2%'}
            default:
                return {}
        }

    }
    const getTextStyle = (positionName) => {
        switch (positionName) {

            case 'beam_offset':
                return {marginTop: '8%', fontSize: !screenMode ? '0.8vw' : '2vw'}
            case 'holder1_offset':
            case 'holder2_offset':
            case 'holder3_offset':
                return {transform: ' rotate(-90deg)', fontSize: !screenMode ? '0.74vw' : '1.8vw'}
            case 'holder1_number':
            case 'holder2_number':
            case 'holder3_number':
                return {color: '#54fcef'}
            default:
                return {fontSize: !screenMode ? '0.8vw' : '2vw'}
        }

    }


    return (
        <div style={{position: 'absolute', ...getStyle(positionName), ...position}}>
            <Typography color={'white'} style={getTextStyle(positionName)}> {text}</Typography>
        </div>
    );
};

export default RackTextContainer;