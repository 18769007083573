import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, InputLabel, MenuItem, Slider} from "@mui/material";
import {setViewerConstants} from "../../redux/reducers/cachedReducer/actions";
import ColorPickerModal from "./ColorPickerModal";
import {useTranslation} from "react-i18next";
import StyledSelect from "../StyledInputs/StyledSelect";



function ModelSettingsInput({entry, type}) {
    const [key, settings] = entry
    const {viewerSettings} = useSelector((state) => state.cachedReducer)
    const dispatch = useDispatch()
    const {t} = useTranslation()


    const getInput = () => {
        switch (settings.type) {
            case "checkbox":
                return <Checkbox
                    sx={{
                        color: "#333333"
                    }}
                    color={'secondary'}
                    id={`${key}${type}`} checked={viewerSettings[type][key]} onChange={(e) => {
                    dispatch(setViewerConstants({
                        ...viewerSettings,
                        [type]: {...viewerSettings[type], [key]: e.target.checked}
                    }))
                }}/>
            case 'slider':
                return <Slider
                    color={'secondary'}
                    style={{width: '50%'}}
                    id={`${key}${type}`}
                    onChangeCommitted={(e, value) => {
                        dispatch(setViewerConstants({
                            ...viewerSettings,
                            [type]: {...viewerSettings[type], [key]: value / settings.factor}
                        }))
                    }}
                    value={viewerSettings[type][key] * settings.factor}
                    valueLabelDisplay="auto"
                    step={settings.step}
                    marks
                    min={settings.min}
                    max={settings.max}
                />
            case 'select':
                return <StyledSelect
                    id={`${key}${type}`}
                    value={viewerSettings[type][key]}
                    onChange={(event) => {
                        dispatch(setViewerConstants({
                            ...viewerSettings,
                            [type]: {...viewerSettings[type], [key]: event.target.value}
                        }))
                    }}
                >
                    {settings.choices.map((item) => {
                        return <MenuItem key={item} value={item}>{t(`${item}`)}</MenuItem>
                    })}

                </StyledSelect>
            case 'color':
                return <ColorPickerModal id={`${key}${type}`}
                                         entry={entry}
                                         type={type}/>
            default:
                return null
        }
    }

    return <div>
        <InputLabel htmlFor={`${key}${type}`} style={{color: 'white'}}>{t(settings.name)}</InputLabel>
        {getInput()}
    </div>


}

export default ModelSettingsInput;