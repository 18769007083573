import React, {useContext, useState} from 'react';
import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    cancelProcessingTaskRequest,
    resetPartsCounter,
    setStatuses
} from "../../../redux/reducers/statusReducer/actions";

import {SocketContext} from "../../../redux/customReducer/SocketContext";
import StyledCheckbox from "../../StyledInputs/StyledCheckbox";
import TableInput from "./TableInput";
import ServiceButton from "../../ServiceButton/ServiceButton";
import {IDLE, SCANNING_BEAM_ACTION, SCANNING_BEAM_IS_RUNNING, SCANNING_BEAM_STATUSES} from "../statusConstants";


function StatusStartSettings() {


    const {
        state: {ws},
    } = useContext(SocketContext);
    const dispatch = useDispatch()

    const {taskId, beamPosition, tableLength, processingTaskIsCanceling} = useSelector((state) => state.statusReducer)

    const [checks, setChecks] = useState({
        racksAreEstablished: false,
        beamIsEstablished: false,
        detailsAreEstablished: false
    })
    const [tableIsOk, setTableIsOk] = useState(true)

    const labelsCheckbox = ['racksAreEstablished', 'beamIsEstablished', 'detailsAreEstablished']
    const {t} = useTranslation()

    const onCheck = (name, checked) => {
        setChecks({...checks, [name]: checked})
    }


    return (
        <>
            <Box style={{backgroundColor: '#141414', borderRadius: '8px', minHeight: '86%'}}>
                <br/>
                <div style={{marginLeft: '30px'}}>
                    <Typography color={'white'} style={{fontSize: '1.7vh'}}>  {t('StatusSettings')}</Typography>
                    <div style={{marginLeft: '-10px'}}>
                        {labelsCheckbox.map((item) => {
                            return <div key={item} style={{display: 'flex', alignItems: 'center'}}>
                                <StyledCheckbox checked={checks[item]}
                                                onChange={(event, checked) => {
                                                    onCheck(item, checked)
                                                }}/>
                                <Typography color={checks[item] ? '#54fcef' : 'white'}
                                            fontSize={'1.6vh'}>{t(item)}</Typography>
                            </div>
                        })}
                    </div>

                    <Typography color={'white'}
                                style={{fontSize: '1.7vh', marginBottom: '4px'}}>  {t('TableStatusInput')}</Typography>
                    <div style={{maxWidth: "700px"}}>
                        <TableInput setTableIsOk={setTableIsOk}/>
                    </div>
                    {!tableIsOk ? <Typography style={{fontSize: '1.5vh', flex: 1}}
                                              color={'yellow'}> {t('TableWarning')} </Typography> : null}
                </div>
            </Box>
            <div style={{marginTop: '20px', display: 'flex', padding: '0px 10px'}}>

                <Button variant={'contained'} color={'secondary'}

                        disabled={
                            !(checks.racksAreEstablished && checks.beamIsEstablished && checks.detailsAreEstablished
                                && tableLength !== 0 && tableIsOk)
                        }
                        onClick={() => {
                            dispatch(resetPartsCounter())
                            ws.emit('start_assembling', {taskId, beamPosition, tableLength})
                            dispatch(setStatuses(SCANNING_BEAM_STATUSES))
                        }}
                        style={{width: '100px'}}>  {t('LaunchButton')} </Button>
                <div style={{marginLeft: '20px'}}>
                    <ServiceButton
                        color={'error'}
                        width={190}
                        variant={'outlined'}
                        buttonName={'DeleteProcessingTask'}
                        loadingCondition={processingTaskIsCanceling}
                        onClick={() => {
                            dispatch(cancelProcessingTaskRequest(taskId))
                        }}/>
                </div>

            </div>
        </>
    );
}

export default StatusStartSettings;
