import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, TableHead, Typography} from "@mui/material";
import useStyles from "./GeneralTableStyle";
import {useTranslation} from "react-i18next";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from "@mui/material/IconButton";

export default function GeneralTable({   isMobile,
                                         headers = [],
                                         rows = [],
                                         onRowClick = null,
                                         activeItem = null,
                                         tableIsLoading = false,
                                         changeFilter, filter
                                     }) {
    const classes = useStyles()
    const [searchText, setSearchText] = React.useState('');
    const {t} = useTranslation();
    const search = (e) => {
      setSearchText(e.target.value)
      switch (e.target.value.length) {
        case 1:
        case 2:
          break;
        default:
          changeFilter({search: e.target.value})
      }
    }

    const sort_table = (type) => {
      if(filter.sortField === type){
        if(filter.sortOrder === 'asc'){
          changeFilter({sortField: type, sortOrder: 'desc'})
        }else{
          changeFilter({sortField: type, sortOrder: 'asc'})
        }
      }else{
        changeFilter({sortField: type, sortOrder: 'desc'})
      }
    }

    return (
        <>
          <TableContainer component={Paper}
                          style={{height: '72px', borderRadius: '5px', backgroundColor: '#141414'}}>
            <Table className={classes.mainTable} stickyHeader>
              <TableHead>
                <TableRow style={{backgroundColor: '#1F1F1F !important', borderBottom: '2px solid #141414'}}>
                  {headers.map((item, index) => {
                    switch (item) {
                      case('CadModelHeader'):
                        return <TableCell key={item} style={ { backgroundColor: '#1F1F1F', borderBottom:'none'}}>
                          <IconButton aria-label="filter" onClick={
                            () => sort_table('name')
                          }>
                            <FilterAltIcon style={{color: 'white'}}/>
                          </IconButton>
                          <Typography color={'white'} fontSize={isMobile ? 12 : 18}
                                      style={{display: 'inline-block'}}> {t(item)}</Typography>
                          <input type="text" value={searchText} onChange={(e) => search(e)}
                                 style={{height: '20px', marginLeft: '5px', width: '80px'}} />
                        </TableCell>
                        break;
                      case('DownloadTimeHeader'):
                        return <TableCell key={item} style={ { backgroundColor: '#1F1F1F', borderBottom:'none'}}>
                          <IconButton aria-label="filter" onClick={
                            () => sort_table('sort_date')
                          }>
                            <FilterAltIcon style={{color: 'white'}}/>
                          </IconButton>
                          <Typography color={'white'} fontSize={isMobile ? 12 : 18}
                                      style={{display: 'inline-block'}}> {t(item)}</Typography>
                        </TableCell>
                        break;
                      default:
                        return <TableCell key={item} style={ { backgroundColor: '#1F1F1F', borderBottom:'none'}}>
                          <Typography color={'white'} fontSize={isMobile ? 12 : 18}> {t(item)}</Typography>
                        </TableCell>
                    }
                  })}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
            <TableContainer component={Paper}
              style={{height: isMobile ? '100%' : 'calc(81vh - 72px)', borderRadius: '5px', backgroundColor: '#141414'}}>
                <Table className={classes.mainTable} stickyHeader>
                    <TableBody>
                        {tableIsLoading ? <TableRow style={{height: '70vh'}}>
                            <TableCell colSpan={5} style={{border: 'none'}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <CircularProgress color={'secondary'} size={50}/></div>
                            </TableCell>
                        </TableRow> :


                        rows.map((row, index) => (
                            <TableRow key={index}
                                      className={row.name === activeItem ? classes.tableBodyRowSelected : classes.tableBodyRow}
                                      onClick={onRowClick ? () => {
                                          onRowClick(row.name, row.count)
                                      } : null}>
                                {Object.entries(row).map(([key, value], index) => {
                                  if(key !== 'count')
                                    return <TableCell key={index} align="left" style={{
                                      borderBottom: row.name !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                                      borderTop: row.name !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                                      backgroundColor: row.name !== activeItem ? "initial" : "#303030",
                                      color: '#EFF3F9',
                                      fontSize: isMobile ? '12px' : '18px',
                                      width: index === 1 ? '275px' : 'initial',
                                      wordBreak: 'break-all'
                                    }}>
                                        {value}
                                    </TableCell>
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
