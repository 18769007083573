import React from 'react';
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AssembledCounter from "../../ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import {EXECUTOR_SUCCESS, PLANNER_SUCCESS} from "../../statusConstants";
import PlannedCounter from "../../ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";

function StatusInfo({status, isMobile}) {
    const {t} = useTranslation()
    return (
        <div style={{height: isMobile ? '100%' : '80vh', color: 'white', flexDirection: 'column'}}
             className={'globalCentered'}>
            <Typography variant={'h4'}> {t(status)}</Typography>
            {status === EXECUTOR_SUCCESS && <AssembledCounter/>}
            {status === PLANNER_SUCCESS && <PlannedCounter/>}
        </div>
    );

}

export default StatusInfo;
