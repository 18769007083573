import React, {useState} from 'react';
import NavBar from "../../components/NavBar/NavBar";
import {Route, useNavigate} from "react-router-dom";
import Routes404 from "../../components/LogicalComponents/Routes/Routes404";
import ModelsPage from "../ModelsPage/ModelsPage";
import TasksPage from "../TasksPage/TasksPage";
import StatusMainPage from "../StatusMainPage/StatusMainPage";
import ServicesPage from "../ServicesPage/ServicesPage";
import SettingsPage from "../SettingsPage/SettingsPage";
import Box from "@mui/material/Box";
import SideBar from "../../components/SideBar/SideBar";
import StreamPage from "../StreamPage/StreamPage";
import MobileNavBar from "../../components/NavBar/MobileNavBar";
import {Button} from "@mui/material";
import {submitProcessingTaskRequest} from "../../redux/reducers/readyTasksReducer/actions";
import PlyViewer from "../PlyViewer/PlyViewer";
import Archive from "../Archive/Archive";
import AssemblyPlanPage from "../AssemblyPlan/AssemblyPlanPage";
import OEE from "../OEE/OEE";

function HomePage({isMobile}) {
    const navigate = useNavigate();
    return (
        <Box style={{height: '100%', display: 'grid', gridTemplateColumns: isMobile ? '100%' : '12% 88%'}}>
            {!isMobile && <SideBar/>}
            <div style={{display:'flex',justifyContent:'center', height: '100vh'}}>
                <Box style={isMobile ? {width: '100%', height: '100%'} : {display: 'grid', gridTemplateRows: '14% 86%', width: '96%'}}>
                    <div style={{height: isMobile ? '50px' : '100%', display: 'flex', alignItems: 'center'}}>
                        {isMobile ?
                          <MobileNavBar/> :
                          <NavBar/>
                        }
                    </div>
                    <div style={isMobile ? {height: 'calc(100% - 90px)', overflowY: 'scroll'} : {marginTop: '0%'}}>
                        {isMobile ?
                              <Routes404>
                                  <Route path='/' element={<TasksPage isMobile={isMobile}/>}/>
                                  <Route path='/status' element={<StatusMainPage isMobile={isMobile}/>}/>
                                  <Route path='/service' element={<ServicesPage isMobile={isMobile}/>}/>
                                  <Route path='/settings' element={<SettingsPage isMobile={isMobile}/>}/>
                              </Routes404>
                          :
                            <Routes404>
                                <Route path='/' element={<ModelsPage/>}/>
                                <Route path='/tasks' element={<TasksPage/>}/>
                                <Route path='/assembly_plan' element={<AssemblyPlanPage/>}/>
                                <Route path='/status' element={<StatusMainPage/>}/>
                                <Route path='/service' element={<ServicesPage/>}/>
                                <Route path='/settings' element={<SettingsPage/>}/>
                                <Route path='/stream_page' element={<StreamPage/>}/>
                                <Route path='/testing' element={<PlyViewer/>}/>
                                <Route path='/archive' element={<Archive/>}/>
                                <Route path='/oee' element={<OEE/>}/>
                            </Routes404>
                        }
                    </div>
                    {isMobile &&
                        <div style={{position: 'fixed', bottom: '0', height: '20px', textAlign: 'center',
                            width: '100%', padding: '10px 0px', background: 'rgba(0,0,0,.5)'}}>
                            <Button color='secondary' variant='contained' onClick={() => {
                                navigate('/')}} style={{padding: '0px', margin: '0px 20px'}}>
                                TASKS
                            </Button>
                            <Button color='secondary' variant='contained' onClick={() => {
                                navigate('/status')}} style={{padding: '0px', margin: '0px 20px'}}>
                                STATUS
                            </Button>
                            <Button color='secondary' variant='contained' onClick={() => {
                                navigate('/service')}} style={{padding: '0px', margin: '0px 20px'}}>
                                SERVICE
                            </Button>
                        </div>
                    }
                </Box>
            </div>
        </Box>
    );
}

export default HomePage;
