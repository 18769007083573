import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const DEFAULT_FILTER = { pageNumber: 1, pageSize: 20 };

export default function useTableFilter(
  loadData,
  defaultFilter = DEFAULT_FILTER,
  index = ''
) {
  const filterName = `filter${index}`;
  const orderName = `order${index}`;
  const [tableFilter, setTableFilter] = useState(null);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const filter = params.get(filterName)
    ? JSON.parse(params.get(filterName))
    : defaultFilter;
  const order = params.get(orderName) ? JSON.parse(params.get(orderName)) : {};
  const nextPage = () => {
    const newFilter = { ...filter, pageNumber: filter.pageNumber + 1 };
    params.set(filterName, JSON.stringify(newFilter));
    navigate(`${pathname}?${params.toString()}`);
  };

  const toPage = (page) => {
    const newFilter = { ...filter, pageNumber: page };
    params.set(filterName, JSON.stringify(newFilter));
    navigate(`${pathname}?${params.toString()}`);
  };
  const changePageSize = (pageSize) => {
    const newFilter = { ...filter, pageSize: pageSize };
    params.set(filterName, JSON.stringify(newFilter));
    navigate(`${pathname}?${params.toString()}`);
  };
  const sortBy = (sortField, sortOrder) => {
    const newFilter = { ...filter, sortField: sortField, sortOrder: sortOrder};
    params.set(filterName, JSON.stringify(newFilter));
    navigate(`${pathname}?${params.toString()}`);
  };
  const changeFilter = (newFilte) => {
    const newFilter = { ...filter, ...newFilte };
    params.set(filterName, JSON.stringify(newFilter));
    navigate(`${pathname}?${params.toString()}`);
  };

  const reloadPage = () => {
    let requestFilter = { ...defaultFilter, ...filter, pageNumber: 1 };
    if (Object.keys(order).length > 0) requestFilter.sorting = order;
    loadData(requestFilter);
  };

  useEffect(() => {
    let requestFilter = { ...defaultFilter, ...filter };
    if (Object.keys(order).length > 0) requestFilter.sorting = order;
    if (JSON.stringify(requestFilter) !== tableFilter) {
      if (
        tableFilter === null &&
        JSON.stringify(requestFilter) !== JSON.stringify(DEFAULT_FILTER)
      ) {
        params.set(filterName, JSON.stringify(requestFilter));
        navigate(`${pathname}?${params.toString()}`, { replace: true });
      }
      loadData(requestFilter);
      setTableFilter(JSON.stringify(requestFilter));
    }
    // eslint-disable-next-line
  }, [search]);

  useEffect(
    () => () => {
      if (window.location.search) {
        if (pathname === window.location.pathname) {
          const params = new URLSearchParams(search);
          params.delete(filterName);
          params.delete(orderName);
          navigate(`${pathname}?${params.toString()}`, { replace: true });
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  return {
    filter,
    order,
    nextPage,
    reloadPage,
    toPage,
    changePageSize,
    sortBy,
    changeFilter,
    index,
  };
}
