import React, {useContext, useEffect, useState} from 'react'
import {Button, Typography} from "@mui/material";
import StyledCheckbox from "../../components/StyledInputs/StyledCheckbox";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import {LOAD_FILES_LIST} from "../../redux/reducers/plyViewerReducer/reducer";
import {clearState} from "../../redux/reducers/plyViewerReducer/actions";
import DownloadProgress from "./DownloadProgress";
import DeskViewer3D from "./DeskViewer/DeskViewer";

export default function PlyViewer() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [allChecked, setAllChecked] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [showDownloads, setShowDownloads] = useState(true)
  const {state: {ws}} = useContext(SocketContext);
  const {taskId: task_id} = useSelector((state) => state.statusReducer)

  useEffect(() => {
    if(!loaded){
      if(task_id){
        dispatch({type: LOAD_FILES_LIST, dispatch, task_id, setShowDownloads});
        setLoaded(true)
      }
    }
    return () => {
      dispatch(clearState())
    };
  }, [task_id])

  return (
    <div style={{ height: '100%' }}>
      <div style={{
        height: '85%',
        flexBasis: '25%'
      }}>
        {showDownloads && <DownloadProgress setShowDownloads={setShowDownloads}/>}
        {/*<ModalCheck/>*/}
        <Typography color={'white'}> {t('ScanResult')}</Typography>
        <div style={{
          backgroundColor: '#141414',
          borderRadius: '8px',
          height: '100%',
          padding: '20px'
        }}>
          <div style={{textAlign: 'center', height: '85%'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <DeskViewer3D isMobile={false}/>
            </div>
          </div>

          <div style={{textAlign: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <StyledCheckbox color={'secondary'} checked={allChecked}
                              onChange={(event, checked) => {
                                // if(checked){dispatch(openPlyViewerModal())}
                                setAllChecked(checked) }}/>
              <Typography color={allChecked ? '#54fcef' : 'white'}
                          fontSize={'1.6vh'}>{t('allChecked')}</Typography>
            </div>
            <Button variant={'contained'} color={"secondary"} style={{marginTop: '10px'}}
                    onClick={()=>{
                      ws.emit('start_planner_and_executor', task_id)
                    }}
                    disabled={!(allChecked)}>{t('ContinueAssembling')}
            </Button>
          </div>

        </div>
      </div>
    </div>
  )
}
