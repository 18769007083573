import React, {useContext} from 'react';

import ProcessAction from "../../GeneralComponents/ProcessAction/ProcessAction";
import StatusScanningError from "../../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../../GeneralComponents/UnknownStatus/UnknownStatus";
import {
    EXECUTOR_ERROR,
    EXECUTOR_IS_RUNNING, EXECUTOR_ROBOT_ERROR,
    EXECUTOR_SUCCESS,

} from "../../statusConstants";
import {useSelector} from "react-redux";
import StatusInfo from "../../GeneralComponents/StatusInfro/StatusInfo";
import ProcessingActionScreen from "../../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import {Button, Modal, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};
const ExecutorComponent = ({screenVersion, isMobile}) => {
    const {executorStatus} = useSelector(state => state.statusReducer)
    const {t} = useTranslation()
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);
    const {state: {ws}} = useContext(SocketContext);
    const {taskId, assemblyName} = useSelector(state => state.statusReducer)

    const statusRender = (status) => {
        switch (status) {
            case EXECUTOR_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={status} isMobile={isMobile}/> :
                    <ProcessingActionScreen status={status} isMobile={isMobile}/>
            case EXECUTOR_ERROR:
            case EXECUTOR_ROBOT_ERROR:
                return <StatusScanningError status={status} screenVersion={screenVersion} isMobile={isMobile}/>
            case EXECUTOR_SUCCESS:
                return <>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <b>{t('assembly_ended_modal_text')}:</b>
                            </Typography>
                            <Button onClick={
                                () => {ws.emit('successfully_end_assembly', taskId, assemblyName) }}
                                sx={{backgroundColor: 'rgba(0,255,0,0.75)', width: '180px', height: '35px',
                                    margin: '20px', "&:hover": {backgroundColor: 'rgba(0,255,0,1)'}}}>
                                <b>{t('succ_end_but')}</b></Button>
                            <br/>
                            <Button onClick={
                                () => {ws.emit('send_task_to_archive', taskId)}}
                            sx={{backgroundColor: 'rgba(255,0,0,0.75)', width: '180px', height: '35px',
                                "&:hover": {backgroundColor: 'rgba(255,0,0,1)'}}}><b>{t('cancel_end_but')}</b></Button>
                        </Box>
                    </Modal>
                    <StatusInfo status={status} isMobile={isMobile}/>
                    </>
            default:
                return <UnknownStatus executor={true} isMobile={isMobile}/>
        }
    }
    return statusRender(executorStatus)
};

export default ExecutorComponent;
