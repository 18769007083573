import React from 'react';
import {Grid, MenuItem, Typography} from "@mui/material";
import {initialViewerTypes} from "../../utils/initialViewerSettings";
import ModelSettingsInput from "./ModelSettingsInput";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import StyledSelect from "../StyledInputs/StyledSelect";
import ExampleModel from "./ExampleModel";
import {setPartsType} from "../../redux/reducers/cachedReducer/actions";


const useStyles = makeStyles({
    selectStyle: {
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
            border:'2px solid'
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#333333",
            border:'2px solid'
        },


    },
    inputText:{
        color: "white !important"
    },

})

function ModelSettings({isMobile}) {

    const {partsType} = useSelector((state) => state.cachedReducer)


    const dispatch = useDispatch()
    const {t} = useTranslation()

    const handleChange = (event) => {
        dispatch(setPartsType(event.target.value))
    };
    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <div style={{height: '100%', position: 'relative'}}>
                    <form>
                        <Typography id="modal-modal-title" variant="h6" style={{fontSize:'17px'}} color={'white'}>
                            {t('settingsModalHeader')}
                        </Typography>

                        <div >
                            <Typography  color={'white'}   style={{fontSize:'15px'}}>{t('partsType')}</Typography>
                            <StyledSelect
                                style={{marginBottom:'40px'}}
                                value={partsType}
                                onChange={handleChange}>
                                <MenuItem value={'parts'}>{t('parts')}</MenuItem>
                                <MenuItem value={'missedParts'}>{t('missedParts')}</MenuItem>
                            </StyledSelect>

                            {Object.entries(initialViewerTypes).map((entry) => {
                                return <ModelSettingsInput key={`${partsType}${entry[0]}`} entry={entry} type={partsType}/>
                            })}

                        </div>

                    </form>
                </div>

            </Grid>
            <Grid item xs={12} sm={6}><ExampleModel/></Grid>
        </Grid>
    );
}

export default ModelSettings;
