import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPlannedTasksListRequest} from "../../redux/reducers/readyTasksReducer/actions";
import {Grid} from "@mui/material";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import TaskButtons from "../../components/ButtonsGroup/TaskButtons/TaskButtons";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import PlannedTasksTable from "./PlannedTasksTable";


function AssemblyPlanPage({isMobile}) {
    const dispatch = useDispatch()
    const {selectedTask, missedPartsList} = useSelector((state) => state.readyTasksReducer)

    useEffect(() => {
        dispatch(getPlannedTasksListRequest())
    }, [dispatch])


    return (

        <Grid container style={isMobile ? {width: '100%', height: '100%', display: 'block'}
          : {width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '45%' : 'initial'}}>
                <PlannedTasksTable isMobile={isMobile}/>
                {/*<TaskSummary/>*/}
            </Grid>
            <Grid item xs={12} sm={6} style={isMobile ? {height: '54%', marginTop: '5px'} : {}}>
                <div style={{height: isMobile ? '100%' : '74vh'}}>
                    <ErrorBoundary message={'Что-то пошло нет'}>
                        <Viewer3DContainer selectedModel={selectedTask} missedPartsList={missedPartsList}
                                           isMobile={isMobile} page={'tasks'}/>
                    </ErrorBoundary>
                </div>
                <div style={{height: isMobile ? 'initial' : '8vh', display: 'flex', alignItems: 'center'}}>
                    <TaskButtons isMobile={isMobile}/>
                </div>
            </Grid>


        </Grid>
    );
}

export default AssemblyPlanPage;
