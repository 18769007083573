import React from 'react';
import {Box, Modal, Typography} from "@mui/material";
import PdfScheme from "../StatusMainPage/PdfScheme";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
function SchemeModal({open, handleClose, selectedModel}) {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-description">
          <PdfScheme selectedModel={selectedModel}/>
        </Typography>
      </Box>
    </Modal>
  );
}

export default SchemeModal;
