import {authAxios} from "../../../settingsFiles/axiosSettings";

export const getReadyTasksList = (filter) => {
    return authAxios.post("tasks/ready", {...filter})
}
export const getPlannedTasksListRequest = () => {
    return authAxios.get("tasks/assembly_plan")
}

export const setPlannedTaskRequest = (model_name, count) => {
    return authAxios.post("tasks/assembly_plan/add", {model_name: model_name, count: count})
}
export const createPendingTask = (task) => {
    return authAxios.post(`tasks/pending`, task)

    // return new Promise((resolve => {
    //     resolve(task)
    // }))

}
