import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Grid, Typography} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import ErrorBoundary from "../LogicalComponents/ErrorBoundary/ErrorBoundary";


function RobotStatus() {
    
    const {status, robotStatusIsLoading} = useSelector(state => state.robotStatusReducer)
    const robotNames = {
        IRB2600: 'IRB 2600',
        IRB6700: 'IRB 6700',
    }

    return (
        <ErrorBoundary message={'Что-то пошло нет'}>
            {Object.entries(status).map(([robot, robotStatus]) => {
                return <div key={robot}>
                    <Grid container>
                        <Grid item xs={3}> <Typography> {robotNames[robot]} </Typography></Grid>
                        {Object.entries(robotStatus).map(([nestedKey, nestedValue]) => {
                            return <Grid item xs={3} className={'globalCentered'} key={nestedKey}>
                                <CircleIcon style={{
                                    fontSize: '11px',
                                    marginRight: '5%',
                                    marginLeft: '20%',
                                    color: robotStatusIsLoading ? 'yellow' : nestedValue === '1' ? '#24FCEF' : "#FF003B"
                                }}/> <Typography> {nestedKey}</Typography>
                            </Grid>
                        })}

                    </Grid>
                </div>
            })}
        </ErrorBoundary>

    );
}

export default RobotStatus;