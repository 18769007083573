import React, {Suspense} from 'react';
import {Canvas} from "@react-three/fiber";
import SingleModel from "../Viewer3DContainer/Viewer3DLoader/SingleModelDynamic/SingleModel";
import CameraControls from "../CameraControls/CameraControls";
import {useSelector} from "react-redux";

const Viewer3D = ({selectedModel,missedPartsList,dispatch, page}) => {
    const {viewerSettings} = useSelector((state) => state.cachedReducer)

    return   <Canvas shadows colorManagement style={{ borderRadius:'13px'}}>
        <Suspense fallback={null}>
            {selectedModel ?
                <SingleModel dispatch={dispatch} viewerSettings={viewerSettings} selectedModel={selectedModel}
                             missedPartsList={missedPartsList} page={page}/> : null}
        </Suspense>
        <CameraControls/>
        <fog attach="fog" args={["white", 0, 40]}/>
        <ambientLight intensity={0.1}/>
        <directionalLight
            intensity={0.5}
            castShadow
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
        />
    </Canvas>
};

export default Viewer3D;
