import React, {useContext, useMemo, useState} from 'react';
import {Button, Typography} from "@mui/material";
import Rack from "../../../Rack/Rack";
import reverseBulk from '../../../../assets/images/reverseBulk.png'
import {useTranslation} from "react-i18next";
import StyledCheckbox from "../../../StyledInputs/StyledCheckbox";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import {useDispatch, useSelector} from "react-redux";
import {resetPartsCounter, setStatuses} from "../../../../redux/reducers/statusReducer/actions";
import {SCANNING_BEAM_STATUSES} from "../../statusConstants";
import ErrorBoundary from "../../../LogicalComponents/ErrorBoundary/ErrorBoundary";
import Viewer3DStatic from "../../../ThreeJsComponents/StatusViewer/Viewer3DStatic/Viewer3DStatic";
import {beamApproximatedAttributes, mainGeometryParser, normalizeBeam} from "../../../../utils/three/loader";

function RotateBeam({isMobile}) {
    const {t} = useTranslation();
    const [checks, setChecks] = useState({
        beamIsRotated: false,
        beamIsEstablished: false,

    })
    const onCheck = (name, checked) => {
        setChecks({...checks, [name]: checked})
    }
    const labelsCheckbox = ['beamIsRotated', 'beamIsEstablished']
    const {
        state: {ws},
    } = useContext(SocketContext);
    const {taskId} = useSelector((state) => state.statusReducer)
    const dispatch = useDispatch()

    return (
        <div style={isMobile ? { height: '100%' } :
            {
            height: '100%',
            display: 'grid',
        }}>
            <div style={{
                height: isMobile ? 'fit-content' : '100%',
                minHeight: '280px',
            }}>
                <Typography color={'white'}> {t('ReverseStep')}</Typography>
                <div style={{
                    backgroundColor: '#141414',
                    borderRadius: '8px',
                    height: '100%'
                }}>
                    <div style={isMobile ? {textAlign: 'center'}: {display: 'inline-block', width: '300px', height: '100%',
                        position: 'relative', verticalAlign: 'middle'}}>
                        <img src={reverseBulk} style={isMobile ? {height: '220px'}: {height: '220px', position: 'absolute', top: 0, bottom: 0,
                            margin: 'auto', paddingLeft: '30px'}}/>
                    </div>
                    <div style={isMobile ? {textAlign: 'center'}: {display: 'inline-block', width: '50%', verticalAlign: 'middle'}}>
                        <Typography color={'white'} style={{fontSize: '1.1rem'}}>  {t('StatusSettings')}</Typography>
                        {labelsCheckbox.map((item) => {
                            return <div key={item} style={{display: 'flex', alignItems: 'center'}}>
                                <StyledCheckbox checked={checks[item]}
                                                onChange={(event, checked) => {
                                                    onCheck(item, checked)
                                                }}/>
                                <Typography color={checks[item] ? '#54fcef' : 'white'}
                                            fontSize={'1rem'}>{t(item)}</Typography>
                            </div>
                        })}
                        <Button variant={'contained'} color={'secondary'} style={{marginTop: '10px'}}
                                onClick={()=>{
                                    dispatch(resetPartsCounter())
                                    ws.emit('start_next_beam_position',taskId)
                                    dispatch(setStatuses(SCANNING_BEAM_STATUSES))
                                }}
                                disabled={!(checks.beamIsEstablished && checks.beamIsRotated)}>{t('ContinueAssembling')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RotateBeam;
