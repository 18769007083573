import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress, TableHead, Typography} from "@mui/material";

import useStyles from "../../components/Tables/GeneralTable/GeneralTableStyle";
import {useTranslation} from "react-i18next";


export default function GeneralTable({   isMobile,
                                       headers = [],
                                       rows = [],
                                       onRowClick = null,
                                       activeItem = null,
                                       tableIsLoading = false
                                     }) {

  const classes = useStyles()
  const {t} = useTranslation();
  return (
    <>
      <TableContainer component={Paper}
                      style={{height: '86px', borderRadius: '5px', backgroundColor: '#141414'}}>
        <Table className={classes.mainTable} stickyHeader>
          <TableHead>
            <TableRow style={{backgroundColor: '#1F1F1F !important', borderBottom: '2px solid #141414'}}>
              {headers.map((item, index) => {
                return <TableCell key={item}

                                  style={ { backgroundColor: '#1F1F1F', borderBottom:'none',
                                    width: index === 0 ? '97px' : index === 1 ? '150px' : 'initial',
                }}>
                  <Typography color={'white'} fontSize={isMobile ? 12 : 18}> {t(item)}</Typography>
                </TableCell>
              })}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}
                      style={{height: isMobile ? '100%' : 'calc(81vh - 86px)', borderRadius: '5px', backgroundColor: '#141414'}}>
        <Table className={classes.mainTable} stickyHeader>
          <TableBody>
            {tableIsLoading ? <TableRow style={{height: '70vh'}}>
                <TableCell colSpan={5} style={{border: 'none'}}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress color={'secondary'} size={50}/></div>
                </TableCell>
              </TableRow> :


              rows.map((row, index) => (
                <TableRow key={index}
                          className={row.name === activeItem ? classes.tableBodyRowSelected : classes.tableBodyRow}
                          onClick={onRowClick ? () => {
                            onRowClick(row.name, row.count)
                          } : null}>
                  {Object.values(row).map((item, index) => {
                      return <TableCell key={index} align="left" style={{
                        borderBottom: row.name !== activeItem ? '1px solid #272727' : '2px solid #54fcef',
                        color: '#EFF3F9',
                        fontSize: isMobile ? '12px' : '18px',
                        width: index === 1 ? '275px' : 'initial',
                        wordBreak: 'break-all'
                      }}>
                        {item}
                      </TableCell>
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
