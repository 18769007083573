import React from 'react';
import {Divider, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import ModelSettings from "../../components/ModelSettings/ModelSettings";
import Paper from "@mui/material/Paper";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";


function SettingsPage({isMobile}) {
    const {t} = useTranslation()
    const divider = <Divider color={'#000000'} style={{height: '2px'}}/>

    return <Paper style={{backgroundColor: '#141414', borderRadius: '10px', position: 'relative', height: '100%'}}>

        <Typography color={'white'} style={{fontSize: '17px'}}> {t('GeneralSettings')}  </Typography>
        {divider}
        <Box style={isMobile ? {padding: '10px'} : {marginLeft: '20px'}}>
            <ErrorBoundary message={'Что-то пошло нет'}>
                <ModelSettings isMobile={isMobile}/>
            </ErrorBoundary>
        </Box>
    </Paper>

}

export default SettingsPage;

