import React, {useContext} from 'react';
import {Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    EXECUTOR_ERROR,
    EXECUTOR_IS_RUNNING, EXECUTOR_ROBOT_ERROR,
    IDLE_STATUSES,
    LABELING_ERROR,
    LABELING_IS_RUNNING, LABELING_ROBOT_ERROR,
    PLANNER_ERROR,
    PLANNER_IS_RUNNING,
    SCANNING_BEAM_ERROR,
    SCANNING_BEAM_IS_RUNNING, SCANNING_BEAM_ROBOT_ERROR,
    SCANNING_LESS_PARTS_ERROR,
    SCANNING_PARTS_ERROR,
    SCANNING_PARTS_IS_RUNNING, SCANNING_PARTS_ROBOT_ERROR,
    SCANNING_REVERSE_PARTS_ERROR,
    SCANNING_UNMATCHED_PARTS_ERROR,
} from "../../statusConstants";
import {useDispatch, useSelector} from "react-redux";
import {
    cancelProcessingTaskRequest,
    setExecutorStatus,
    setLabelingStatus,
    setPlannerStatus,
    setScanningBeamStatus,
    setScanningPartsStatus,
    setStatuses
} from "../../../../redux/reducers/statusReducer/actions";
import {SocketContext} from "../../../../redux/customReducer/SocketContext";
import DeskViewer3D from "../../../ThreeJsComponents/DeskViewer/DeskViewer3D";
import AssembledCounter from "../../ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import PlannedCounter from "../../ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";


function StatusScanningError({status, screenVersion, isMobile}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {
        state: {ws},
    } = useContext(SocketContext);
    const {taskId, beamPosition, tableLength} = useSelector((state) => state.statusReducer)


    const returnSocketEmitAndStatusName = (taskStatus) => {
        switch (taskStatus) {
            case SCANNING_BEAM_ERROR:
            case SCANNING_BEAM_ROBOT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setScanningBeamStatus(SCANNING_BEAM_IS_RUNNING)),
                    emitName: 'start_assembling',
                    emitPayload: {taskId, beamPosition, tableLength}
                }

            case SCANNING_PARTS_ERROR:
            case SCANNING_REVERSE_PARTS_ERROR:
            case SCANNING_LESS_PARTS_ERROR:
            case SCANNING_UNMATCHED_PARTS_ERROR:
            case SCANNING_PARTS_ROBOT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setScanningPartsStatus(SCANNING_PARTS_IS_RUNNING)),
                    emitName: 'start_scan_parts',
                    emitPayload: taskId
                }
            case LABELING_ERROR:
            case LABELING_ROBOT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setLabelingStatus(LABELING_IS_RUNNING)),
                    emitName: 'start_label_task',
                    emitPayload: taskId
                }
            case PLANNER_ERROR:
                return {
                    changeStatus: ()=> dispatch(setPlannerStatus(PLANNER_IS_RUNNING)),
                    emitName: 'start_planner_task',
                    emitPayload: taskId
                }
            case EXECUTOR_ERROR:
            case EXECUTOR_ROBOT_ERROR:
                return {
                    changeStatus: ()=> dispatch(setExecutorStatus(EXECUTOR_IS_RUNNING)),
                    emitName: 'start_executor_task',
                    emitPayload: taskId
                }
            default:
                return {changeStatus: null, emitName: '', emitPayload: {}}
        }
    }

    const startRescan = () => {
        const {changeStatus, emitName, emitPayload} = returnSocketEmitAndStatusName(status)
        changeStatus?.()
        ws.emit(emitName, emitPayload)
    }
    const stopAssembling = () => {
        dispatch(setStatuses(IDLE_STATUSES))
        dispatch(cancelProcessingTaskRequest(taskId))
    }

    return (
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width:"100%"}}>
                <Typography variant={'h4'} color={'white'} style={{textAlign: "center", paddingBottom: "20px"}}>
                    {t(status)}
                    {(status === EXECUTOR_ROBOT_ERROR || status === EXECUTOR_ERROR) && <AssembledCounter/>}
                    {status === PLANNER_ERROR && <PlannedCounter/>}
                </Typography>
                {status === SCANNING_REVERSE_PARTS_ERROR && <DeskViewer3D isMobile={isMobile}/>}
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                    <Button color={'error'} variant={!screenVersion ? 'outlined' : 'contained'}
                            style={{flexBasis: '200px'}} onClick={() => {
                        stopAssembling()
                    }}> {t('StopAssembling')} </Button>
                    <Button color={'success'} variant={!screenVersion ? 'outlined' : 'contained'}
                            style={{flexBasis: '250px', marginLeft: '50px'}}
                            onClick={() => {
                                startRescan()
                            }}> {t('MakeReScan')}</Button>
                </div>
            </div>
        </div>
    );
}

export default StatusScanningError;
