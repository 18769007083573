import React from 'react';
import MeshMaterial from "../../../MeshMaterial/MeshMaterial";


function SingleModelPart({geometry, viewerSettings}) {


    const {parts, missedParts} = viewerSettings
    const settings = geometry.type === 'missedPart' ? missedParts : parts


    const getMesh = (mesh) => {
        if (!mesh) return
        mesh.applyMatrix4(geometry.matrix)

    }


    return <mesh
        ref={getMesh}
        castShadow receiveShadow
        position={geometry.position}
        geometry={geometry.geometry} scale={1}>

        <MeshMaterial
            type={settings.materialType}
            attach="material"
            color={settings.color}
            transparent={true}
            opacity={settings.opacity}
            wireframe={settings.wireframe}
        />
    </mesh>
}

export default SingleModelPart;