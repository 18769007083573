import React from 'react';
import {useTranslation} from "react-i18next";
import StatusStartSettings from "../../StatusStartSettings/StatusStartSettings";


function MountingComponent() {
    const {t} = useTranslation()

    return <div style={{height: '100%'}}>
        <StatusStartSettings/>
    </div>
}

export default MountingComponent;
