import React from 'react';
import {AppBar, Avatar, Button, ButtonGroup, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import RobotStatus from "../RobotStatus/RobotStatus";
import SettingsIcon from '@mui/icons-material/Settings';

import CustomLink from "../LogicalComponents/Routes/CustomLink";
import {makeStyles} from "@mui/styles";
import {GETTING_INITIAL_INFORMATION, IDLE} from "../StatusesComponets/statusConstants";
import {openStatusModalViewer} from "../../redux/reducers/layoutReducer/actions";


const useStyles = makeStyles((theme) => ({
    appBarStyle: {height: '53%', borderRadius: '10px'},
    mainFlexContainer: {height: '100%', display: 'flex', alignItems: 'center'},
    textStyle: {
        flexBasis: '150px',
    },
    robotStatusStyle: {
        marginLeft: 'auto',
        marginRight: '50px',
        flexBasis: '280px',
    },
    settingsButton: {flexBasis: '50px'},
    languageButtons: {flexBasis: '150px'},
    adminText: {flexBasis: '70px'},
    avatar: {
        border: '3px solid #2C2C2C'
    },
    avatarContainer: {
        flexBasis: '75px'
    }
}))

function NavBar() {

    const {chosenSection} = useSelector((state) => state.cachedReducer)
    const {taskStatus, assemblyName} = useSelector(state => state.statusReducer)
    const dispatch = useDispatch()

    const pathNameSection = {
        '/': 'Models',
        '/tasks': 'Tasks',
        '/assembly_plan': 'AssemblyPlan',
        '/status': 'Status',
        '/service': 'Service',
        '/settings': 'Settings',
    }
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('currentLanguage', language)
    };

    return (
        <AppBar position="static" className={classes.appBarStyle}>
            <div className={classes.mainFlexContainer}>
                  <Typography className={classes.textStyle} style={{
                    marginLeft: '46px',
                    fontWeight: 'bold',
                    fontSize: '24px'
                    }}>  {t(pathNameSection[chosenSection])} </Typography>

                {(![IDLE, GETTING_INITIAL_INFORMATION].includes(taskStatus) && chosenSection === '/status') &&
                   <>
                       <Typography fontSize={19}>{t('StatusInProcess')}:</Typography>
                        <Typography style={{textTransform: 'initial', marginLeft:'10px'}}>{assemblyName}</Typography>
                   </>
                }

                <div className={classes.robotStatusStyle}>
                    <RobotStatus/>
                </div>

                <CustomLink to={'/settings'} className={classes.settingsButton}>
                    <SettingsIcon style={{color: 'white'}}/>
                </CustomLink>
                 <ButtonGroup aria-label="outlined primary button group" className={classes.languageButtons}>
                     {['RUS', 'ENG'].map((item) => {
                         return <Button
                           key={item}
                           color={'secondary'}
                           variant={i18n.language === item ? 'contained' : 'outlined'}
                           onClick={() => {
                               changeLanguage(item);
                           }}
                         >{item}</Button>
                     })}
                </ButtonGroup>
                <Typography className={classes.adminText} style={{fontSize: '14px'}}> Admin </Typography>
                <div className={classes.avatarContainer}>
                    <Avatar alt="Admin Admin" src="/assets/logoNoBg.svg"
                    className={classes.avatar}/>
                </div>
            </div>
        </AppBar>
    );
}

export default NavBar;
