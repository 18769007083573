import React from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Paper from "@mui/material/Paper";
import {getArchivedTasksListRequest} from "../../redux/reducers/archiveReducer/actions";
import ArchiveTable from "./ArchiveTable";
import {Grid} from "@mui/material";
import SelectedTaskView from "./SelectedTaskView";
import useTableFilter from "../../utils/useTableFilter";

export default function Archive() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const loadData = (filter) => dispatch(getArchivedTasksListRequest(t, filter));
  const {filter, changeFilter} = useTableFilter(loadData, {
    sortField: 'sort_date',
    sortOrder: 'desc',
  });

  return(
    <Paper style={{backgroundColor: '#141414', borderRadius: '10px', position: 'relative',
      height: '100%', display: 'flex'}}>
      <Grid item xs={12} sm={6}>
        <ArchiveTable filter={filter} changeFilter={changeFilter}/>
      </Grid>
      <Grid item xs={12} sm={6} style={{padding: '40px', overflow: 'scroll'}}>
        <SelectedTaskView/>
      </Grid>
    </Paper>
  )
}
