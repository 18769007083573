import {makeStyles} from "@mui/styles";
import backGroundImage from "../../assets/images/gg.jpg";

const useStyles = makeStyles(theme => ({
    boxStyle: {
        backgroundColor: theme.palette.primary.main
    },
    bgImage:{  backgroundImage: `url(/assets/bglogin.jpg)`,
        backgroundSize: 'cover',
        height: ' 100%',
        overflow: 'hidden',
        backgroundPosition: 'center',
        position:'relative'}
}))

export default useStyles