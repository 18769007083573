import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@mui/material/Paper";
import {Button, CircularProgress, Grid} from "@mui/material";
import '../../../node_modules/react-vis/dist/style.css';
import {
  Hint,
  HorizontalGridLines,
  MarkSeries, VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis
} from "react-vis";
import {getGraphDataRequest, getGraphExcelRequest} from "../../redux/reducers/archiveReducer/actions";
import ExcelIcon from './excel_logo.png';
import IconButton from "@mui/material/IconButton";

export default function OEE() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {filter_start, filter_end, first_load, maxValue, graphDataLoading, weights, total_weight,
    graphData} = useSelector((state) => state.archiveReducer)
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    if(!first_load){
      setFromDate(filter_start)
      setToDate(filter_end)
      return
    }
    let firstDay = new Date();
    let lastDay = new Date();
    firstDay.setFullYear(firstDay.getFullYear(), firstDay.getMonth(), 1);
    lastDay.setFullYear(lastDay.getFullYear(), lastDay.getMonth() + 1, 0);
    setFromDate(firstDay.toISOString().substring(0, 10))
    setToDate(lastDay.toISOString().substring(0, 10))
    dispatch(getGraphDataRequest({'start': firstDay.toISOString().substring(0, 10),
      'end': lastDay.toISOString().substring(0, 10)}))
  }, [])

  const renew_graph = () => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if(diffDays > 31){
      alert('Количество дней не может превышать 31 дней')
      return
    }
    dispatch(getGraphDataRequest({'start': fromDate, 'end': toDate}))
  }

  return(
    <>
      <Paper style={{backgroundColor: '#141414', borderRadius: '10px', position: 'relative',
        height: '85px', display: 'flex', width: '100%', color: 'white'}}>
        <Grid item xs={12} sm={12} style={{margin: '15px 20px'}}>
          <div>{t('filter')}:</div>
          <div>{t('From_date')}:
            <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)}
                   style={{marginRight: '10px'}}/>
            {t('To_date')}:
            <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)}
                   style={{marginRight: '10px'}}/>
            <Button variant="contained" color="secondary" component="label" size={"small"}
                    onClick={() => renew_graph()} style={{marginLeft: '10px'}}>
              {t('show')}
            </Button>
            <IconButton style={{marginLeft: '10px'}} onClick={() => {dispatch(getGraphExcelRequest({'start': fromDate, 'end': toDate}))}}>
              <img src={ExcelIcon} style={{height: '40px'}}/>
            </IconButton>
          </div>
        </Grid>
      </Paper>
      <Paper style={{backgroundColor: 'white', borderRadius: '10px', position: 'relative',
        height: 'calc(100% - 85px)', display: 'flex', width: '100%'}}>
        <Grid item xs={12} sm={12} style={{textAlign: 'center', overflow: 'scroll', color: 'black'}}>
          {graphDataLoading ?
            <CircularProgress color="primary" style={{padding: '20px'}}/>
            :  <>
              <div style={{width: '100%', position: 'relative', height: '50px'}}>{t('graph1_title')}
                <div style={{position: 'absolute', right: '20%', zIndex: '9', top: '35%'}}>{t('Total_assembled')}: {maxValue.total_min}</div>
                <div style={{position: 'absolute', right: '20%', zIndex: '9', top: '0%'}}>{t('Total_weight')}: {total_weight.toFixed(2)}{t('kg')}</div>
                <div style={{position: 'absolute', right: '20%', zIndex: '9', top: '70%'}}>{t('total_parts')}: {graphData.total_parts}</div>
              </div>
              <XYPlot height={300} width={1600} yDomain={[0, maxValue.max_graph_1]} xType='ordinal'>
                <HorizontalGridLines/>
                <XAxis style={{stroke: 'black'}}/>
                <YAxis style={{stroke: 'black'}}/>
                <MarkSeries data={graphData.graph_1}/>
                {graphData.graph_1 && graphData.graph_1.map((item, index) => {
                  return <Hint value={item} style={{fontSize: 14, marginLeft: '-5px'}} align={{vertical: 'top', horizontal: 'right'}} key={index}>
                    <div style={{padding: '0px 2px', color: 'black'}}>
                      {weights[index] !== 0 && <div>{weights[index].toFixed(2)}{t('kg')}</div>}
                      <h3>{item['y']}</h3>
                    </div>
                  </Hint>
                })}
              </XYPlot>
              <div>{t('graph2_title')}</div>
              <XYPlot height={300} width={1600} yDomain={[0, maxValue.max_graph_2]} xType='ordinal'>
                <HorizontalGridLines/>
                <XAxis style={{stroke: 'black'}}/>
                <YAxis style={{stroke: 'black'}}/>
                <VerticalBarSeries data={graphData.graph_2} />
                {graphData.graph_2 && graphData.graph_2.map((item, index) => {
                  return <Hint value={item} style={{fontSize: 14, marginLeft: '-10px'}} align={{vertical: 'top', horizontal: 'right'}} key={index}>
                    <div style={{padding: '0px 2px', color: 'black'}}>
                      <h3>{item['y']}</h3>
                    </div>
                  </Hint>
                })}
              </XYPlot>
              <div>{t('graph3_title')}</div>
              <XYPlot height={300} width={1600} yDomain={[0, maxValue.max_graph_3]} xType='ordinal'>
                <HorizontalGridLines/>
                <XAxis style={{stroke: 'black'}}/>
                <YAxis style={{stroke: 'black'}}/>
                <VerticalBarSeries data={graphData.graph_3} />
                {graphData.graph_3 && graphData.graph_3.map((item, index) => {
                  return <Hint value={item} style={{fontSize: 14, marginLeft: '-14px'}} align={{vertical: 'top', horizontal: 'right'}} key={index}>
                    <div style={{padding: '0px 2px', color: 'black'}}>
                      <h3>{item['y']}%</h3>
                    </div>
                  </Hint>
                })}
              </XYPlot>
            </>}
        </Grid>
      </Paper>
    </>
  )
}
