const initialViewerSettings = {
    parts: {
        opacity: 1,
        wireframe: false,
        materialType: 'standardMesh',
        color:'#0E731E'
    },
    missedParts: {
        opacity: 1,
        wireframe: false,
        materialType: 'standardMesh',
        color:'#FF0000'
    }
}




export const initialViewerTypes = {
    materialType: {
        type: 'select',
        name: 'viewermaterialType',
        // choices: ['standardMesh', 'physicalMesh', 'normalMesh',
        //     'basicMesh', 'depthMesh', 'lambertMesh',
        //     'matcapMesh', 'phongMesh','toonMesh']
        choices: ['standardMesh',  'normalMesh',
            'basicMesh','toonMesh']
    },
    color: {type: 'color', name: 'viewercolor'},
    opacity: {type: 'slider', name: 'viewerOpacity', min: 0, max: 100, step: 10, factor: 100},

    wireframe: {type: 'checkbox', name: 'viewerwireframe'},




}

export default initialViewerSettings