import React from 'react';
import WebSocketSettings from "../../websocket/WebSocketSettings";
import LocalStorageHandler from "../LocalStorageHandler/LocalStorageHandler";
import MessageComponent from "./MessageComponent/MessageComponent";
import RedirectPath from "./Routes/RedirectPath";

const LogicComponent = () => {
    return (
        <>
            <WebSocketSettings/>
            <LocalStorageHandler/>
            <MessageComponent/>
            <RedirectPath/>
        </>
    );
};

export default LogicComponent;