import React, {useEffect, useRef} from 'react';
import {Text} from "@react-three/drei";

function DeskViewerSingleModelPart({geometry, showId}) {
  const meshRef = useRef()

  useEffect(() => {
    if (meshRef.current && geometry.generalMatrix) {
      meshRef.current.applyMatrix4(geometry.generalMatrix)
    }
  }, [meshRef])

  return <mesh
    ref={meshRef}
    castShadow receiveShadow
    geometry={geometry.geometry}>
    <meshBasicMaterial
      attach="material"
      color={geometry.color}
      opacity={0.5}
    />
    {showId && <>
      <Text color="white" anchorX="center" anchorY="middle" fontSize={0.06} position={[0, 0, -0.02]}>
        {geometry.part_id}
      </Text>
      <Text color="white" anchorX="center" anchorY="middle" fontSize={0.06} position={[0,0,0.02]}>
        {geometry.part_id}
      </Text>
    </>}
  </mesh>
}

export default DeskViewerSingleModelPart;
