import React, {useContext} from 'react';
import {Box, Button} from "@mui/material";
import {makeStyles} from "@mui/styles";
import LinksItems from "./LinksItems";
import ServiceButton from "../ServiceButton/ServiceButton";
import {useDispatch, useSelector} from "react-redux";
import {GETTING_INITIAL_INFORMATION, IDLE, MOUNTING} from "../StatusesComponets/statusConstants";
import {useTranslation} from "react-i18next";
import {SocketContext} from "../../redux/customReducer/SocketContext";
import {cancelProcessingTaskRequest} from "../../redux/reducers/statusReducer/actions";


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        height: '100vh',
        width: '11vw',
        textAlign: 'center'

    },
    imageStyle: {height: '110px', width: '120px', marginTop: '25%', marginBottom: '20%'},
    linksContainer: {
        width: '100%',
        marginTop: '500px !important'
    },
    archiveButton: {
        width: '150px',
        marginTop: '10px'
    }

}))


const SideBar = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const {processingTaskIsCanceling, taskStatus, taskId} = useSelector(state => state.statusReducer)
    const {
        state: {ws},
    } = useContext(SocketContext);


    return (
        <Box className={classes.container}>
            <img src="/assets/logoNoBg.svg" alt="#" className={classes.imageStyle}/>
            <LinksItems className={classes.linksContainer}/>
            <div style={{marginTop: '100px'}}>
                {![IDLE, MOUNTING, GETTING_INITIAL_INFORMATION].includes(taskStatus) &&
                <>
                    <ServiceButton
                        color={'error'}
                        width={150}
                        variant={'outlined'}
                        buttonName={'DeleteProcessingTask'}
                        loadingCondition={processingTaskIsCanceling}
                        onClick={() => {
                            dispatch(cancelProcessingTaskRequest(taskId))
                        }}/>
                    <Button color={'secondary'} variant={'outlined'}
                            onClick={() => {
                                ws.emit('send_task_to_archive', taskId)
                            }}
                            className={classes.archiveButton}>{t('archiveButton')}</Button>
                </>
                }
            </div>

        </Box>
    );
};

export default SideBar;
