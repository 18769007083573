import {call, put, takeLatest} from "redux-saga/effects";
import {loading, set_data} from "./actions";
import {loadFile, loadFileList, loadLocalFile} from "./requests";
import {sendGeneralMessage} from "../layoutReducer/actions";
import {LOAD_FILES_LIST} from "./reducer";
import {PLYLoader} from "three/addons/loaders/PLYLoader";

// from local files
function* loadPlyViewerLocalFileList(action) {
  try {
    yield put(loading(true))
    const loader = new PLYLoader()
    let data = [];
    for(let i=0; i < 10; i++){
        data[i] = {
          "name": i+'.ply',
          "percentage": 0,
          "loaded": false,
          "positions": null
        }
    }
    yield put(set_data(data))
    for(let i=0; i < 10; i++){
      let {data: file} = yield call(loadLocalFile, i, i, action.dispatch)
      if(file !== 'File not found'){
        let plyGeometry = loader.parse(file);
        for(let j=0; j < plyGeometry.attributes.position.array.length; j++){
          plyGeometry.attributes.position.array[j] = plyGeometry.attributes.position.array[j]/1000
        }
        data[i] = {
          "name": i+'.ply',
          percentage: 100,
          "loaded": true,
          "positions": plyGeometry.attributes.position.array ?? null
        }
      }
    }
    yield put(set_data(data))
    yield put(loading(false))
    yield put(sendGeneralMessage("Ply файлы успешно загружены", 'success'))
    yield new Promise(resolve =>
      setTimeout(() => {
        action.setShowDownloads(false);
        resolve();
      }, 5000)
    );
  } catch (e) {
    console.log(e)
    yield put(sendGeneralMessage("Ошибка загрузки ply файлов", 'error'))
  }
}

function* loadPlyViewerFileList(action) {
  try {
    if(!action.task_id){
      return
    }
    yield put(loading(true))
    let {data} = yield call(loadFileList, action.task_id)
    let result = [];
    for(let i=0; i < data.length; i++){
      result[i] = {
        "name": data[i],
        "percentage": 0,
        "loaded": false,
        "positions": null
      }
    }
    yield put(set_data(result))
    const loader = new PLYLoader()
    for(let i=0; i < result.length; i++){
      let {data: file} = yield call(loadFile, result[i].name, action.task_id, i, action.dispatch)
      if(file !== 'File not found'){
        let plyGeometry = loader.parse(file);
        for(let j=0; j < plyGeometry.attributes.position.array.length; j++){
          plyGeometry.attributes.position.array[j] = plyGeometry.attributes.position.array[j]/1000
        }
        result[i] = {
          "name": result[i].name,
          percentage: 100,
          "loaded": true,
          "positions": plyGeometry.attributes.position.array ?? null
        }
      }
    }
    yield put(set_data(result))
    yield put(loading(false))
    yield put(sendGeneralMessage("Ply файлы успешно загружены", 'success'))
    yield new Promise(resolve =>
      setTimeout(() => {
        action.setShowDownloads(false);
        resolve();
      }, 5000)
    );
  } catch (e) {
    console.log(e)
    yield put(sendGeneralMessage("Ошибка загрузки ply файлов", 'error'))
  }
}


export default function* statusSaga() {
  // yield takeLatest(LOAD_FILES_LIST, loadPlyViewerLocalFileList)
  yield takeLatest(LOAD_FILES_LIST, loadPlyViewerFileList)
}
