import {authAxios, streamAxios} from "../../../settingsFiles/axiosSettings";


export const getSimulationModelsTasks = (filter) => {
    return authAxios.post('tasks/simulation', {...filter})
}

export const getSingleModelTask = (modelName) => {
    return authAxios.post('models/by_name', {
        model: modelName
    })
}


export const updateSimulationTaskStatus = (task, status) => {
    return authAxios.put(`tasks/simulation/${task}/${status}`)
    // return  new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve()
    //     },2000)
    // })

}

export const deleteModelApi = (modelName) => {
    return authAxios.post('models/delete_by_name', {
        model: modelName
    })
    // return  new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve()
    //     },2000)
    // })

}

export const startRosSimulation = (task) => {
    return authAxios.post(`tasks/simulation/${task}`)
}
