import React, {memo, useEffect, useMemo, useRef} from 'react';
import SingleModelPart from "./SignleModelPart";
import {Plane, Text} from "@react-three/drei";
import {turnOffViewerLoading} from "../../../../../redux/reducers/modelsReducer/actions";
import {mainGeometryParser} from "../../../../../utils/three/loader";
import {AxesHelper} from "three";


function SingleModel({dispatch, viewerSettings, selectedModel, missedPartsList, page}) {

    const geomList = useMemo(() => mainGeometryParser({selectedModel, missedPartsList}), [selectedModel, missedPartsList])
    const groupRef = useRef()

    useEffect(() => {
        if (groupRef.current) {
            dispatch?.(turnOffViewerLoading())
        }

    }, [groupRef, geomList])
    return (
        <group ref={groupRef}>
            {geomList.map((item, index) => <SingleModelPart key={index} geometry={item}
                                                            viewerSettings={viewerSettings}/>)}
            <Plane
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -1, 0]}
                args={[1000, 1000]}
            >
                <meshStandardMaterial attach="material" color="white"/>
            </Plane>
          {page === 'v_rabote' &&
            <>
              <mesh position={[1.5, -0.7, 0]} rotation={[-Math.PI / 2, 0, Math.PI/2]}>
                <boxGeometry args={[1, 0.3, 1]}/>
                <meshStandardMaterial color={'gray'}/>
              </mesh>
              <Text color="red" anchorX="center" anchorY="middle" fontSize={0.5} position={[1.5, -0.5, 0.51]}
                    rotation={[0, 0, 0]}>
                1
              </Text>
              <primitive object={new AxesHelper(10)}/>
            </>
          }
        </group>
    );
}

export default memo(SingleModel, (prevProps, nextProps) =>
    prevProps.selectedModel === nextProps.selectedModel
);



