import React from 'react';
import LoginForm from "../../components/LoginForm/LoginForm";
import {Box, Grid} from "@mui/material";
import useStyles from "./LoginPageStyles";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";


function LoginPage({isMobile}) {
    const classes = useStyles()
    const {isAuthenticated} = useSelector(state => state.cachedReducer);
    return !isAuthenticated ?
        <Box className={classes.boxStyle}>
            <Grid container style={{height: '100vh'}}>
                <Grid item xs={12} sm={6} className={classes.bgImage}>
                    <img src="/assets/logoNoBg.svg" alt="#"
                         style={{height: '170px', width: '180px', position: 'absolute', top: '50px',left:'40px'}}/>
                </Grid>
                <Grid item xs={12} sm={6} className={'globalCentered'} style={isMobile ? {
                    overflow: 'hidden', height: 'fit-content', width: '100%', position: 'absolute',
                    background: 'rgba(0,0,0,.5)', padding: '10px', top: '50%', transform: 'translate(0, -50%)'} : {}}>
                    <LoginForm isMobile={isMobile}/>
                </Grid>

            </Grid>
        </Box> : <Navigate to="/"/>

}

export default LoginPage;
