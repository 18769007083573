import React, {Suspense, useState} from 'react';
import {Canvas} from "@react-three/fiber";
import {CircularProgress, Typography} from "@mui/material";
import SingleModelStatic from "./SingleModelStatic/SingleModelStatic";
import CameraControls from "../../CameraControls/CameraControls";


function Numbering({top, left}) {
    return <div style={{
        position: 'absolute',
        left: left,
        top: top,
        zIndex:20,
        border: '3px solid #f50e02',
        borderRadius:'6px',
        width: '40px',
        height: '40px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}><Typography color={'white'} fontSize={30} >1</Typography></div>
}


function Viewer3DStatic({view, geomList,attributes, isMobile}) {


    const [viewerIsLoading, setViewerIsLoading] = useState(true)

    const views = {
        top: {
            sides: ['left', 'right'],
            gridRotation: [Math.PI / 2, 0, 0],
            numbering:{left: isMobile ? '86%' : '90%', top:'45%'}
        },
        front: {
            sides: ['front'],
            gridRotation: [Math.PI / 2, 0, 0],
            numbering:{left: isMobile ? '1%' : '5%', top:'45%'}
        },
        side: {
            sides: ['left','right'],
            gridRotation: [-Math.PI / 2, 0, 0],
            numbering:{left: isMobile ? '86%' : '90%', top:'45%'}

        },
    }
    const value = views[view]

    return (
        <div style={{
            height: '100%',
            border: '2px solid',
            position: 'relative',
            backgroundColor: '#303030',
            borderRadius: '11px'

        }}>
            <Numbering left={value.numbering.left} top={value.numbering.top}/>
            <div style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                display: viewerIsLoading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center'
            }}>

                <CircularProgress color={'secondary'} size={50}/>
            </div>
            <div style={{display: isMobile ? 'block' : 'flex', height: '100%', visibility: !viewerIsLoading ? 'visible' : 'hidden'}}>
                {value.sides.map((item, index) => {
                    return <React.Fragment key={item}>
                        <Canvas style={isMobile && (view ==='top' || view === 'side') && {display: 'inline-block', width: '50%'}}>
                            <Suspense fallback={null}>
                                {geomList.length ? <SingleModelStatic geomList={geomList}
                                                                      side={item}
                                                                      attributes={attributes}
                                                                      setViewerIsLoading={(item === 'front' || index === 1) ? setViewerIsLoading : null}
                                                                      view={view}
                                                                     /> : null}
                            </Suspense>
                            <gridHelper args={[50, 50, `#5f5f5f`, `#5f5f5f`]}
                                        rotation={value.gridRotation}
                            />
                        </Canvas>
                        {index !== value.sides.length-1 ? <div style={isMobile ? {width:'15px', zIndex: 1, position: 'absolute',
                            height: '100%', backgroundColor:"black", display: 'inline-block'}
                        : {width: "40px", height: '100%', backgroundColor:"black"}}></div> : <></>}
                    </React.Fragment>
                })}
            </div>


        </div>

    );
}

export default Viewer3DStatic;
