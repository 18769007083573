import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GeneralTable from "./GeneralTable";
import {getSingleReadyTaskRequest} from "../../redux/reducers/readyTasksReducer/actions";
import useCreateTableData from "./useCreateTableData";

function PlannedTasksTable({isMobile}) {

  const headers = isMobile ? ['CadModelHeader', 'WillBeAssembledHeader','RotationHeader']
    : ['AssemblyCount', 'CadModelHeader','DownloadTimeHeader', 'WillBeAssembledHeader','RotationHeader', '']
  const {selectedTaskName, plannedTasksList,plannedTasksAreLoading} = useSelector((state => state.readyTasksReducer))
  const dispatch = useDispatch()
  const {rows}  = useCreateTableData(plannedTasksList, isMobile, dispatch)
  const onRowClick = (id) => {
    dispatch(getSingleReadyTaskRequest(id))
  }
  return <GeneralTable headers={headers} rows={rows} onRowClick={onRowClick} isMobile={isMobile}
                       activeItem={selectedTaskName} tableIsLoading={plannedTasksAreLoading}/>
}

export default PlannedTasksTable;
