import {useSelector} from "react-redux";
import StatusScanningError from "../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../GeneralComponents/UnknownStatus/UnknownStatus";
import ProcessAction from "../GeneralComponents/ProcessAction/ProcessAction";
import {
    SCANNING_LESS_PARTS_ERROR,
    SCANNING_PARTS_ERROR,
    SCANNING_PARTS_IS_RUNNING, SCANNING_PARTS_ROBOT_ERROR, SCANNING_PARTS_SUCCESS,
    SCANNING_REVERSE_PARTS_ERROR,
    SCANNING_UNMATCHED_PARTS_ERROR
} from "../statusConstants";
import React from "react";
import ProcessingActionScreen from "../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";
import PlyViewer from "../../../pages/PlyViewer/PlyViewer";


const ScanningPartsComponent = ({screenVersion = false, isMobile}) => {
    const {scanningPartsStatus} = useSelector(state => state.statusReducer)

    const statusRender = (status) => {
        // return <StatusScanningError status={SCANNING_REVERSE_PARTS_ERROR}
        //                             screenVersion={screenVersion} isMobile={isMobile}/>
        switch (status) {
            case SCANNING_PARTS_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={SCANNING_PARTS_IS_RUNNING}/> :
                    <ProcessingActionScreen status={SCANNING_PARTS_IS_RUNNING}/>
            case SCANNING_PARTS_SUCCESS:
                return <PlyViewer/>
            case SCANNING_PARTS_ERROR:
            case SCANNING_REVERSE_PARTS_ERROR:
            case SCANNING_LESS_PARTS_ERROR:
            case SCANNING_UNMATCHED_PARTS_ERROR:
            case SCANNING_PARTS_ROBOT_ERROR:
                return <StatusScanningError status={status}
                                            screenVersion={screenVersion} isMobile={isMobile}/>
            default:
                return <UnknownStatus isMobile={isMobile}/>
        }
    }
    return statusRender(scanningPartsStatus)
};

export default ScanningPartsComponent;
