import React from 'react';
import {useSelector} from "react-redux";
import {PLANNER_ERROR, PLANNER_IS_RUNNING, PLANNER_SUCCESS} from "../../statusConstants";
import ProcessAction from "../../GeneralComponents/ProcessAction/ProcessAction";
import StatusScanningError from "../../GeneralComponents/StatusScanningError/StatusScanningError";
import UnknownStatus from "../../GeneralComponents/UnknownStatus/UnknownStatus";
import StatusInfo from "../../GeneralComponents/StatusInfro/StatusInfo";
import ProcessingActionScreen from "../../../ScreensComponent/ProcessingActionScreen/ProcessingActionScreen";

const PlannerComponent = ({screenVersion, isMobile}) => {
    const {plannerStatus} = useSelector(state => state.statusReducer)

    const statusRender = (status) => {
        switch (status) {
            case PLANNER_IS_RUNNING:
                return !screenVersion ? <ProcessAction status={PLANNER_IS_RUNNING} isMobile={isMobile}/> :
                    <ProcessingActionScreen status={PLANNER_IS_RUNNING} isMobile={isMobile}/>
            case PLANNER_ERROR:
                return <StatusScanningError status={PLANNER_ERROR} screenVersion={screenVersion}/>
            case PLANNER_SUCCESS:
                return  <StatusInfo status={status} isMobile={isMobile}/>
            default:
                return <UnknownStatus planner={true} isMobile={isMobile}/>
        }
    }
    return statusRender(plannerStatus)
};

export default PlannerComponent;
