import React from 'react';
import GeneralTable from "../GeneralTable/GeneralTable";
import {useDispatch, useSelector} from "react-redux";
import {getSingleModelRequest} from "../../../redux/reducers/modelsReducer/actions";
import useCreateTableData from "../../LogicalComponents/Hooks/useCreateTableData";

function ModelsTable({isMobile, filter, changeFilter}) {
    const headers = isMobile ? ['CadModelHeader','WillBeAssembledHeader','RotationHeader']
      : ['','CadModelHeader','DownloadTimeHeader', 'WillBeAssembledHeader','RotationHeader'];
    const {simulationTasks, selectedModelName, simulationTasksAreLoading} = useSelector((state => state.modelsReducer))
    let {rows} = useCreateTableData(simulationTasks, isMobile)
    const dispatch = useDispatch()
    const onRowClick = (id) => {
        dispatch(getSingleModelRequest(id))
    }
    return (
        <GeneralTable headers={headers} rows={rows} onRowClick={onRowClick} isMobile={isMobile}
                      activeItem={selectedModelName} tableIsLoading={simulationTasksAreLoading}
                      filter={filter} changeFilter={changeFilter}/>
    );
}

export default ModelsTable;
