import React, {useEffect, useRef} from 'react';
import {Plane, PointMaterial} from "@react-three/drei";
import {useSelector} from "react-redux";
import DeskViewerSingleModelPart from "./DeskViewerSinglePart";
import * as THREE from "three";

function MyPoints(props) {
  return (
    <>
      {props.files &&
        props.files.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.positions ?
                <points>
                  <bufferGeometry>
                    <bufferAttribute usage={THREE.DynamicDrawUsage} attach="attributes-position" count={item.positions.length / 3}
                                     array={item.positions} itemSize={3}/>
                  </bufferGeometry>
                  <PointMaterial transparent color={'#11529c'} size={1} sizeAttenuation={false} depthWrite={false}/>
                </points>
                :
                <></>
              }
            </React.Fragment>
          )
        })
      }
    </>
  )
}

function DeskViewerModels({geomList, showId}) {
  const meshRef = useRef()
  const plyViewerReducer = useSelector((state) => state.plyViewerReducer)
  const deg2rad = degrees => degrees * (Math.PI / 180);

  return (
    <group ref={meshRef} position={[0,-5, 0]} rotation={[deg2rad(0), deg2rad(0), deg2rad(0)]}>
      <MyPoints files={plyViewerReducer.files}/>
      {geomList.map((item, index) => <DeskViewerSingleModelPart key={index} geometry={item} showId={showId}/>)}
    </group>
  );
}

export default DeskViewerModels;
