import {call, put, takeLatest} from "redux-saga/effects";
import {CANCEL_PROCESSING_TASK_REQUEST} from "./types";
import {
    cancelProcessingTaskFail,
    cancelProcessingTaskSuccess,
    setStatuses
} from "./actions";
import {cancelProcessingTaskRequestCall} from "./requests";
import {sendGeneralMessage} from "../layoutReducer/actions";
import {IDLE_STATUSES} from "../../../components/StatusesComponets/statusConstants";


function* cancelProcessingTaskSaga(action) {
    try {
        yield call(cancelProcessingTaskRequestCall, action.payload)
        yield put(cancelProcessingTaskSuccess())
        yield put(setStatuses(IDLE_STATUSES))
    } catch (e) {
        yield put(cancelProcessingTaskFail())
        yield put(sendGeneralMessage("При удалении задачи произошла ошбика", 'error'))


    }
}

export default function* statusSaga() {
    // yield takeLatest(CALCULATE_INITIAL_TASK_DATA, calculateInitialTaskDataSaga)
    yield takeLatest(CANCEL_PROCESSING_TASK_REQUEST, cancelProcessingTaskSaga)

}
