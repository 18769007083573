import React, {useState} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {userLoginRequest} from "../../redux/reducers/userReducer/actions";
import useStyles from "./LoginFormStyles";


function LoginForm({isMobile}) {

    const [formState, setFormState] = useState({password: '', username: 'admin'})
    const dispatch = useDispatch()
    const {userIsLoading} = useSelector((state) => state.userReducer)
    const labelStyle = {color: '#FFFFFF', fontSize: '16px', fontStyle: 'normal', fontFamily: '"Fira sans",sans-serif'}

    const onInputChange = (e) => {
        setFormState({...formState, [e.target.name]: e.target.value})
    }

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(userLoginRequest({...formState, grant_type: "password"}))
    }
    const classes = useStyles()


    return (

        <form onSubmit={(e) => onSubmit(e)} style={isMobile ? {width: '100%'} : {}}>
            <Typography textAlign={'center'} className={classes.headerControl}> Control Panel</Typography>
            <Typography textAlign={'center'} className={classes.secondHeader}>Welcome back! Please login to
                your account.</Typography>
            <div>
                <TextField
                    label="Username"
                    variant="standard"
                    name="username"
                    color={'secondary'}
                    value={formState.username}
                    required={true}
                    onChange={(e) => onInputChange(e)}
                    className={classes.input}
                    inputProps={{className: classes.inputText}}
                    InputLabelProps={{
                        classes: {
                            asterisk: classes.asterisk
                        },
                        style: labelStyle,
                    }}
                    margin="normal"

                />
            </div>
            <div>
                <TextField label="Password" variant="standard"
                           color={'secondary'}
                           margin="normal"
                           className={classes.input}
                           type={'password'}
                           inputProps={{className: classes.inputText}}
                           asterisk={{display: 'none !important'}}
                           value={formState.password}
                           name="password"
                           required={true}
                           onChange={(e) => onInputChange(e)}
                           InputLabelProps={{
                               classes: {
                                   asterisk: classes.asterisk
                               },
                               style: labelStyle,
                           }}


                />

            </div>
            <div className={classes.checkboxLabel}>
                <FormControlLabel
                    classes={{
                        label: classes.checkBoxLabel, // Pass your override css here
                    }}
                    control={
                        <Checkbox defaultChecked color={'secondary'}/>
                    } label="Remember me"/>
                {/*<Link className={classes.forgotLink} to={'#'}>*/}
                {/*    Forgot password*/}
                {/*</Link>*/}
            </div>
            <div className={classes.buttonContainer}>
                <Button color={'secondary'} variant={'contained'} type={'submit'}
                        className={classes.loginButton}>{!userIsLoading ? <Typography>Login</Typography> :
                    <CircularProgress color={'primary'} size={30}/>} </Button>
            </div>
        </form>
    );
}

export default LoginForm;
