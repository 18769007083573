import React from 'react';
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import cubeGif from '../../../assets/images/cube.gif'
import AssembledCounter from "../../StatusesComponets/ExecutorPlannerLabeling/ExecutorComponent/AssembledCounter";
import {EXECUTOR_IS_RUNNING, PLANNER_IS_RUNNING} from "../../StatusesComponets/statusConstants";
import PlannedCounter from "../../StatusesComponets/ExecutorPlannerLabeling/PlannerComponent/PlannedCounter";

const ProcessingActionScreen = ({status, isMobile}) => {
    const {t} = useTranslation()

    return (
        <div style={{height: isMobile ? '100%' : '80vh'}} className={'globalCentered'}>
            <div style={{textAlign: "center"}}>
               <img src={cubeGif}/>
                <Typography variant={'h4'} color={'white'}> {t(status)}</Typography>
                {status === EXECUTOR_IS_RUNNING && <AssembledCounter/>}
                {status === PLANNER_IS_RUNNING && <PlannedCounter/>}
            </div>
        </div>
    );
};

export default ProcessingActionScreen;
