export const GET_SIMULATION_TASKS_REQUESTS = 'GET_SIMULATION_TASKS_REQUESTS'
export const UPDATE_SIMULATIONS = 'UPDATE_SIMULATIONS'
export const GET_SIMULATION_TASKS_SUCCESS = 'GET_SIMULATION_TASKS_SUCCESS'
export const GET_SIMULATION_TASKS_FAIL = 'GET_SIMULATION_TASKS_FAIL'
export const GET_SINGLE_TASK_REQUEST = 'GET_SINGLE_TASK_REQUEST'
export const GET_SINGLE_TASK_SUCCESS = 'GET_SINGLE_TASK_SUCCESS'
export const GET_SINGLE_TASK_FAIL = 'GET_SINGLE_TASK_FAIL'

export const TURN_ON_VIEWER_LOADING = 'TURN_ON_VIEWER_LOADING'
export const TURN_OFF_VIEWER_LOADING = 'TURN_OFF_VIEWER_LOADING'

export const SET_SINGLE_MODELS_STATE = 'SET_SINGLE_MODELS_STATE'


export const UPDATE_MODEL_ASSEMBLING_STATUS_REQUEST = 'UPDATE_MODEL_STATUS_REQUEST'
export const UPDATE_MODEL_ASSEMBLING_STATUS_SUCCESS = 'UPDATE_MODEL_ASSEMBLING_STATUS_SUCCESS'
export const UPDATE_MODEL_ASSEMBLING_STATUS_FAIL = 'UPDATE_MODEL_ASSEMBLING_STATUS_FAIL'


export const UPDATE_MODEL_SEND_TO_TASK_REQUEST = 'UPDATE_MODEL_SEND_TO_TASK_REQUEST'
export const UPDATE_MODEL_SEND_TO_TASK_SUCCESS = 'UPDATE_MODEL_SEND_TO_TASK_SUCCESS'
export const UPDATE_MODEL_SEND_TO_TASK_FAIL = 'UPDATE_MODEL_SEND_TO_TASK_FAIL'


export const DELETE_MODEL_REQUEST = 'DELETE_MODEL_REQUEST'
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS'
export const DELETE_MODEL_FAIL = 'DELETE_MODEL_FAIL'




export const CLEAR_MODELS_PAGE_RESOURCES = 'CLEAR_MODELS_PAGE_RESOURCES'

export const UPDATE_MODEL_STATE_IN_REDUCER = 'UPDATE_MODEL_STATE_IN_REDUCER'

export const SET_MODELS_MISSED_PART_LIST='SET_MODELS_MISSED_PART_LIST'
