import {LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS} from "./types";

const initialState = {
    userIsLoading: false,
    

}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {...state, userIsLoading: true}
        case LOGIN_SUCCESS:
            return {...state, userIsLoading: false}
        case LOGIN_FAIL:
            return {...state, userIsLoading: false}
        default:
            return state;
    }
};

export default reducer;