import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        height: '74vh',
        width: '100%',
        marginLeft:'2%',
        border: '3px solid #141414',
        borderRadius:'13px',
        borderColor: theme.palette.primary.main,
        position: 'relative ',
        backgroundColor:'white',

    },
    viewerHidden: props => ({
        borderRadius:'13px',
        visibility:
            !props.viewerIsLoading && !props.viewerFirstTimeRendered ? "visible" : 'hidden',

    }),
    loaderContainer: props => ({
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        backgroundColor: props.viewerFirstTimeRendered ? '#141414':'white',

        width: '100%',
        display: props.viewerIsLoading || props.viewerFirstTimeRendered ? 'flex' : "none",
        justifyContent: 'center',
        alignItems: 'center',
    }),
    buttonsGroup: props => ({
        position: 'absolute',
        top: '93%',
        left: '0px',
        // height: '100%',
        // width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        visibility:
            !props.viewerIsLoading && !props.viewerFirstTimeRendered ? "visible" : 'hidden',
    }),
    settingsButton: props => ({
        position: 'absolute',
        top: '3%',
        right: '3%',
        justifyContent: 'center',
        alignItems: 'center',

    }),
    settingsIcon:{
        fontSize:'30px'
    }

}));

export default useStyles

export const useStylesMobile = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        position: 'relative ',
        backgroundColor:'white',
    },
    viewerHidden: props => ({
        borderRadius:'13px',
        visibility:
          !props.viewerIsLoading && !props.viewerFirstTimeRendered ? "visible" : 'hidden',

    }),
    loaderContainer: props => ({
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        backgroundColor: props.viewerFirstTimeRendered ? '#141414':'white',

        width: '100%',
        display: props.viewerIsLoading || props.viewerFirstTimeRendered ? 'flex' : "none",
        justifyContent: 'center',
        alignItems: 'center',
    }),
    buttonsGroup: props => ({
        position: 'absolute',
        top: '93%',
        left: '0px',
        // height: '100%',
        // width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        visibility:
          !props.viewerIsLoading && !props.viewerFirstTimeRendered ? "visible" : 'hidden',
    }),
    settingsButton: props => ({
        position: 'absolute',
        top: '3%',
        right: '3%',
        justifyContent: 'center',
        alignItems: 'center',

    }),
    settingsIcon:{
        fontSize:'30px'
    }

}));
