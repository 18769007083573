import React, {useRef} from 'react';
import {Plane} from "@react-three/drei";
import DeskViewerSingleModelPart from "./DeskViewerSingleModelPart";

function DeskViewerSingleModel({geomList, showId}) {
    const meshRef = useRef()
    const deg2rad = degrees => degrees * (Math.PI / 180);

    return (
        <group ref={meshRef} position={[-1.5,0,2.5]} rotation={[deg2rad(-90), deg2rad(90), deg2rad(-30)]}>
            {geomList.map((item, index) => <DeskViewerSingleModelPart key={index} geometry={item} showId={showId}/>)}
            <Plane
                receiveShadow
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, -1, 0]}
                args={[1000, 1000]}
            >
                <meshStandardMaterial attach="material" color="white"/>
            </Plane>
        </group>
    );
}

export default DeskViewerSingleModel;
