import React, {useContext, useEffect} from 'react';
import {LABELING_AND_PLANNING_ACTION,} from "../statusConstants";
import PlannerComponent from "./PlannerComponent/PlannerComponent";
import ExecutorComponent from "./ExecutorComponent/ExecutorComponent";
import LabelingComponent from "./LabelingComponent/LabelingComponent";
import {SocketContext} from "../../../redux/customReducer/SocketContext";


const ExecutorPlannerLabeling = ({status, screenVersion, isMobile}) => {
    const {
      state: {ws},
    } = useContext(SocketContext);

    useEffect(() => {
      ws.emit('request_assembly_counters')
    }, [])
    return (
        <div style={isMobile? {height: '100%'} : {height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '50% 50%'}}>
            <div style={{height: isMobile ? '50%' : '100%'}}>
                <PlannerComponent screenVersion={screenVersion} isMobile={isMobile}/>
            </div>
            <div style={{height: isMobile ? '50%' : '100%', borderLeft: '2px solid #141414'}}>
                {status === LABELING_AND_PLANNING_ACTION ? <LabelingComponent screenVersion={screenVersion}/> :
                      <ExecutorComponent screenVersion={screenVersion} isMobile={isMobile}/>
                }
            </div>
        </div>
    );
};

export default ExecutorPlannerLabeling;
