import {
    GET_ARCHIVE_TASKS_REQUEST,
    GET_ARCHIVE_TASKS_SUCCESS,
    GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_EXCEL, GET_GRAPH_EXCEL_SUCCESS,
    SELECT_ARCHIVED_TASK,
} from "./types";

export const getArchivedTasksListRequest = (t, filter) => ({
    type: GET_ARCHIVE_TASKS_REQUEST, payload: t, filter
})

export const getArchiveTasksListSuccess = (data) => ({
    type: GET_ARCHIVE_TASKS_SUCCESS, payload: data
})

export const selectArchivedTask = (data) => ({
    type: SELECT_ARCHIVED_TASK, payload: data
})

export const getGraphDataRequest = (dates) => ({
    type: GET_GRAPH_DATA, dates
})
export const getGraphDataApiRequestSuccess = (data, maxValue, dates, weights, total_weight) => ({
    type: GET_GRAPH_DATA_SUCCESS, payload: data, maxValue, dates, weights, total_weight
})
export const getGraphExcelRequest = (dates) => ({
    type: GET_GRAPH_EXCEL, dates
})
export const getGraphExcelRequestSuccess = () => ({
    type: GET_GRAPH_EXCEL_SUCCESS
})
