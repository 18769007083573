import LoginPage from "./pages/LoginPage/LoginPage";
import './App.css'
import Routes404 from "./components/LogicalComponents/Routes/Routes404";
import {BrowserRouter, Route} from "react-router-dom";
import RequireAuth from "./components/LogicalComponents/Routes/ProtectedRoute";
import Page404 from "./pages/Page404/Page404";
import HomePage from "./pages/HomePage/HomePage";
import Screen1Page from "./pages/Screen1Page/Screen1Page";
import Screen2Page from "./pages/Screen2Page/Screen2Page";
import React, {useState} from "react";
import LogicComponent from "./components/LogicalComponents/LogicComponent";
import DebugComponent from "./components/DebugComponent/DebugComponent";
import {theme} from "./settingsFiles/theme";
import {CustomReducerProvider} from "./redux/customReducer/SocketContext";
import {ThemeProvider} from "@mui/material/styles";
import {mobileTheme} from "./settingsFiles/mobileTheme";


function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  window.addEventListener('resize', () => {setIsMobile( window.innerWidth <= 500)});

    return (
      <ThemeProvider theme={isMobile ? mobileTheme : theme}>
        <CustomReducerProvider>
          <BrowserRouter>
            <React.StrictMode>
              <LogicComponent/>
              <Routes404>
                <Route path='/login' element={<LoginPage isMobile={isMobile}/>}/>
                <Route
                  path="/*"
                  element={
                    <RequireAuth>
                      <HomePage isMobile={isMobile}/>
                    </RequireAuth>
                  }
                />
                <Route path='screen1/' element={<Screen1Page/>}/>
                <Route path='screen2/' element={<Screen2Page />}/>
                <Route path="/notfoundpage" element={<Page404/>}/>
                <Route path="/debug" element={<DebugComponent/>}/>
              </Routes404>
            </React.StrictMode>
          </BrowserRouter>
        </CustomReducerProvider>
      </ThemeProvider>
    );
}

export default App;
