import React from 'react';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

function PdfScheme({selectedModel}) {

  return (
    <>
      <Box style={{backgroundColor: '#141414', borderRadius: '8px', minHeight: '86%', height: '76vh'}}>
        <iframe src={"data:application/pdf;base64,"+selectedModel?.file?.drawingPDF} height="100%" width="100%"></iframe>
      </Box>
    </>
  );
}

export default PdfScheme;
