import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getReadyTasksListRequest} from "../../redux/reducers/readyTasksReducer/actions";
import {Button, Grid} from "@mui/material";
import ReadyTasksTable from "../../components/Tables/ReadyTasksTable/ReadyTasksTable";
import Viewer3DContainer from "../../components/ThreeJsComponents/Viewer3DContainer/Viewer3DContainer";
import TaskButtons from "../../components/ButtonsGroup/TaskButtons/TaskButtons";
import ErrorBoundary from "../../components/LogicalComponents/ErrorBoundary/ErrorBoundary";
import {useTranslation} from "react-i18next";
import ModalAddToPlan from "./ModalAddToPlan";
import useTableFilter from "../../utils/useTableFilter";

function TasksPage({isMobile}) {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const {selectedCount, selectedTask, missedPartsList, selectedTaskName} = useSelector((state) => state.readyTasksReducer)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const loadData = (filter) => dispatch(getReadyTasksListRequest(filter));
    const {filter, changeFilter, reloadPage} = useTableFilter(loadData, {
        sortField: 'sort_date',
        sortOrder: 'desc',
    });

    return (
        <Grid container style={isMobile ? {width: '100%', height: '100%', display: 'block'}
          : {width: '100%', height: '100%'}} spacing={isMobile ? 0 : 3}>
            <Grid item xs={12} sm={6} style={{height: isMobile ? '45%' : 'initial'}}>
                <ReadyTasksTable isMobile={isMobile} changeFilter={changeFilter} filter={filter}/>
                {/*<TaskSummary/>*/}
            </Grid>
            <Grid item xs={12} sm={6} style={isMobile ? {height: '54%', marginTop: '5px'} : {}}>
                <div style={{height: isMobile ? '100%' : '74vh'}}>
                    <ErrorBoundary message={'Что-то пошло нет'}>
                        <Viewer3DContainer selectedModel={selectedTask} missedPartsList={missedPartsList}
                                           isMobile={isMobile} page={'tasks'}/>
                    </ErrorBoundary>
                </div>
                <div style={{height: isMobile ? 'initial' : '8vh', display: 'flex', alignItems: 'center'}}>
                    {selectedTaskName && <Button variant="contained" color="secondary" style={{ marginLeft: '20px',
                        height: '40px', width: '180px'}} onClick={handleOpen}> {t('AddToPlan')} </Button>}
                    <TaskButtons isMobile={isMobile}/>
                </div>
            </Grid>
            <ModalAddToPlan open={open} handleClose={handleClose} count={selectedCount} reloadPage={reloadPage}/>
        </Grid>
    );
}

export default TasksPage;
