import React from 'react';
import {Box, Modal, TableHead, Typography} from "@mui/material";
import useTableFilter from "../../utils/useTableFilter";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
function PartsModal({open, handleClose}) {
  const {simulationTasks, selectedModelName} = useSelector((state => state.modelsReducer))
  let parts = simulationTasks.find(item => item.name === selectedModelName)
  if(parts){
    parts = simulationTasks.find(item => item.name === selectedModelName)['parts']
  }else{
    parts = []
  }
  const {t} = useTranslation();

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"><b>{t('Part')}</b></TableCell>
                    <TableCell align="left"><b>{t('Quantity')}</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parts && Object.entries(parts).map(([k,v]) =>
                    <TableRow
                      key={k}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{k}</TableCell>
                      <TableCell align="left">{v}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Box>
      </Modal>
  );
}

export default PartsModal;
