import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {meshesParserRevert} from "../../../utils/three/loader";
import {Canvas} from "@react-three/fiber";
import DeskViewerScene from "./DeskViewerScene/DeskViewerScene";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";

function DeskViewer3D({isMobile}) {
    const {t} = useTranslation()
    const {selectedModel, scannedParts} = useSelector(state => state.statusReducer)
    const geomList = meshesParserRevert(selectedModel, scannedParts)
    const [showId, setShowID] = useState(false);

    return (
        <div style={isMobile ? { backgroundColor: '#303030', height:'100%', width:'100%', position:"relative"}
          : { backgroundColor: '#303030',borderRadius:'25px', height:'60vh', width:'100%', position:"relative"}}>
            <Canvas shadows colorManagement style={{borderRadius:'25px'}}>
                <DeskViewerScene geomList={geomList} showId={showId}/>
            </Canvas>
          <FormControlLabel
            value="start"
            control={<Checkbox onChange={(e) => {setShowID(e.target.checked)}} sx={{
                color: "white",
                '&.Mui-checked': {
                  color: "white",
                },
              }}/>}
            label={t("ShowId")}
            labelPlacement="start"
            style={isMobile ? {position:"relative", color:"white"}
              : {position:"absolute", top:"0", right:"0",paddingRight:"20px", color:"white"}}
          />
        </div>
    );
}

export default DeskViewer3D;
