import React from 'react';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    bgImage:{  backgroundImage: `url(/assets/bglogin.jpg)`,
        backgroundSize: 'cover',
        height: ' 100vh',
        overflow: 'hidden',
        backgroundPosition: 'center',
        position:'relative'}
}))

const Idle = () => {
    const classes = useStyles()
    return (
        <div className={classes.bgImage}>
            <img src="/assets/logoNoBg.svg" alt="#" style={{height:'170px',width:'180px'}}/>
        </div>
    );
};

export default Idle;