import React from 'react'
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {DownloadProgressStyle} from "./DownloadProgressStyle";
import {CircularProgressWithLabel} from "./ProgressBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export default function DownloadProgress({setShowDownloads}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const plyViewerReducer = useSelector((state) => state.plyViewerReducer)


  return (
    <DownloadProgressStyle>
      <IconButton onClick={() => setShowDownloads()} className={'close_icon'}>
        <CloseIcon />
      </IconButton>
      <Typography color={'black'} style={{textAlign: 'center'}}> {t('DownloadParts')}</Typography>
      {plyViewerReducer.files && plyViewerReducer.files.map((item, index) => {
        return (
          <div className={'list_item'} key={index}>
            <div className={'title'}>{item.name}</div>
            <div className={'progress_bar'}>
              <CircularProgressWithLabel value={item.percentage} color="secondary"/>
            </div>
          </div>
        )
      })}
    </DownloadProgressStyle>
  )
}
