import React from 'react';
import Paper from "@mui/material/Paper";
import RobotControlServices from "../../components/RobotControlServices/RobotControlServices";

function ServicesPage({isMobile}) {
    return <Paper style={{
        backgroundColor: '#141414',
        borderRadius: '10px',
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <div style={{width: isMobile ? '100%' : '30%'}}>
        <RobotControlServices isMobile={isMobile}/>
        </div>
    </Paper>
}

export default ServicesPage;
